import React, { useState, useEffect } from "react";
import {
  Layout,
  Typography,
  Tooltip,
  Card,
  Row,
  Col,

  Button,
  Table,
  Dropdown,
  message,
  Input,
  Popconfirm,
  notification,
} from "antd";
import {
  MenuOutlined,
  CloseOutlined,
  MinusOutlined,
  MoreOutlined,
  FileDoneOutlined,
  QrcodeOutlined,
  EditOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { IconBackpack, IconDeviceMobile } from '@tabler/icons-react';
import DrawerMenu from "../components/DrawerMenu"; // Importa o componente reutilizável do drawer
import "./LockerManagement.css"; // CSS para estilização
import {
  insertNewEquipment,
  updateEquipmentStatus,
} from "../services/equipmentService"; // Importa funções de serviço
import { getAccessLevel } from "../services/userServices";
import { userLogout } from "../services/userServices";
import AddEquipmentModal from "../components/AddEquipmentModal";

import { useNavigate } from "react-router-dom";
import EditEquipmentModal from "../components/EditEquipmentModal";
import * as XLSX from "xlsx";
import socket from "../utils/socket";
import {LOCKER_STATUSES, assignLocker, fetchAllLockers, fetchLockerByStatus } from "../services/lockerService";
import AssignLockerModal from "../components/AssignLockerModal";
import { triggerFocus } from "antd/es/input/Input";
import InfoLockerModal from "../components/InfoLockerModal";
import LockerQrCodeModal from "../components/LockerQrCodeModal";

const { Header, Content } = Layout;
const { Text } = Typography;

const EquipmentManager = () => {
  const navigate = useNavigate(); 

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [activeTab, setActiveTab] = useState("");
  const [loading, setLoading] = useState(false);
  const [lockersInUse, setLockersInUse] = useState([]);
  const [lockersAvailable, setLockersAvailable] = useState([]);
  const [lockersDeleted, setLockersDeleted] = useState([]);
  const [lockersInactive, setLockersInactive] = useState([]);
  const [smallLockersAvailable, setSmallLockersAvailable] = useState([])
  const [smallLockersInUse, setSmallLockersInUse] = useState([])
  const [bigLockersAvailable, setBigLockersAvailable] = useState([])
  const [bigLockersInUse, setBigLockersInUse] = useState([])
  const [percentages, setPercentages] = useState({
    inUse: 0,
    available: 0,
    maintenance: 0,
    inactive: 0,
    twelvehours: 0,
    twentyfourhours: 0,
  }); // Estado para porcentagens
  const [pageSize, setPageSize] = useState(25);
  const [filteredData, setFilteredData] = useState([]);
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [selectedLocker, setSelectedLocker] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [qrModalVisible, setQrModalVisible] = useState(false);
  const [accessLevel, setAccessLevel] = useState(null);
  const [lockerData, setLockerData] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [assignLockerModalVisible, setAssignLockerModalVisible] = useState(false);

  const showDrawer = () => setDrawerVisible(true);
  const closeDrawer = () => setDrawerVisible(false);
  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);

  const fetchLockers = async () => {
    setLoading(true);
    try {
      const lockers = await fetchAllLockers();
      const allLockers = lockers[0];
      setLockerData(allLockers);
      setFilteredData(allLockers); // Atualiza filteredData assim que a página é carregada.
      const userLevel = await getAccessLevel();
      setAccessLevel(userLevel);

      const [inUseLockers, availableLockers, deletedLockers, inactiveLockers] =
        await Promise.all([
            fetchLockerByStatus(LOCKER_STATUSES.IN_USE),
            fetchLockerByStatus(LOCKER_STATUSES.AVAILABLE),
            fetchLockerByStatus(LOCKER_STATUSES.DELETED),
            fetchLockerByStatus(LOCKER_STATUSES.INACTIVE),

        ]);

        const smallLockersAvailable = allLockers.filter(
            (lockers) => 
                lockers.size === "CELULAR" && lockers.status === "LIVRE"
        )

        const bigLockersAvailable = allLockers.filter(
            (lockers) => 
                lockers.size === "BOLSA" && lockers.status === "LIVRE"
        )

        const smallLockersInUse = allLockers.filter(
            (lockers) => 
                lockers.size === "CELULAR" && lockers.status === "OCUPADO"
        )

        const bigLockersInUse = allLockers.filter(
            (lockers) => 
                lockers.size === "BOLSA" && lockers.status === "OCUPADO"
        )

      const equipments12h = lockers.filter(
        (equip) =>
          equip.usage_duration_category === "in_use_12h" &&
          equip.equipment_status === "Em uso"
      );
      const equipments24h = lockers.filter(
        (equip) =>
          equip.usage_duration_category === "in_use_24h" &&
          equip.equipment_status === "Em uso"
      );

      setLockersInUse(inUseLockers);
      setLockersAvailable(availableLockers);
      setLockersDeleted(deletedLockers);
      setLockersInactive(inactiveLockers);
      setSmallLockersAvailable(smallLockersAvailable);
      setSmallLockersInUse(smallLockersInUse);
      setBigLockersAvailable(bigLockersAvailable);
      setBigLockersInUse(bigLockersInUse);

      //setEquipments24h(equipments24h);

      const total = allLockers.length;
      setPercentages({
        smallInuse: ((smallLockersInUse.length / total) * 100).toFixed(0),
        smallAvailable: ((smallLockersAvailable.length / total) * 100).toFixed(0),
        bigInUse: ((bigLockersInUse.length / total) * 100).toFixed(0),
        bigAvailable: ((bigLockersAvailable.length / total) * 100).toFixed(0),
        twelvehours: ((equipments12h.length / total) * 100).toFixed(0),
        twentyfourhours: ((equipments24h.length / total) * 100).toFixed(0),
      });

    } catch (error) {
      console.error("Erro ao buscar equipamentos:", error);
      message.error("Erro ao buscar equipamentos");
    } finally {
      setLoading(false);
      setActiveTab("inventario");
    }
  };

  //useeffect to retrieve equipments on page load
  useEffect(() => {
    fetchLockers();
  }, []);

  useEffect(() => {
    socket.on("lockerTransaction", (data) => {
      if (data.success === true) {
        fetchLockers();
      }
    });
    // Cleanup on unmount
    return () => {
      socket.off("lockerTransactioned");
    };
  }, []);

  const cardData = [
    { 
      title: "Totais", 
      rows: [
        { 
          icon: <IconBackpack 
            size="30" 
            stroke="2" 
            onClick={() => {
              if (currentFilter === 'all_available') {
                setCurrentFilter(null);
                setFilteredData(lockerData);
                setActiveTab('inventario');
              } else {
                setCurrentFilter('all_available');
                handleTabChange('inventario');
              }
            }} 
            style={{ 
              cursor: 'pointer',
              opacity: 1 
            }} 
          />, 
          number: bigLockersAvailable.length + bigLockersInUse.length, 
          percentage: "100%" 
        },
        { 
          icon: <IconDeviceMobile  
            size="30" 
            stroke="2" 
            onClick={() => {
              if (currentFilter === 'all_inuse') {
                setCurrentFilter(null);
                setFilteredData(lockerData);
                setActiveTab('inventario');
              } else {
                setCurrentFilter('all_inuse');
                handleTabChange('inventario');
              }
            }}
            style={{ 
              cursor: 'pointer',
              opacity: 1 
            }}
          />, 
          number: smallLockersAvailable.length + smallLockersInUse.length, 
          percentage: "100%" 
        }
      ]
    },
    {
      title: "Armários p/ Bolsas",
      rows: [
        { 
          icon: <Tooltip title={'LIVRES'}><IconBackpack 
            size="30" 
            color="green" 
            onClick={() => {
              if (currentFilter === 'big_available') {
                setCurrentFilter(null);
                setFilteredData(lockerData);
                setActiveTab('inventario');
              } else {
                setCurrentFilter('big_available');
                handleTabChange('big_available');
              }
            }}
            style={{ 
              cursor: 'pointer',
              opacity: currentFilter === 'big_available' ? 1 : 0.6 
            }}
          /></Tooltip>, 
          number: bigLockersAvailable.length, 
          percentage: `${percentages.bigAvailable}%` 
        },
        { 
          icon: <Tooltip title={'OCUPADOS'}><IconBackpack 
            size="30" 
            color="red" 
            onClick={() => {
              if (currentFilter === 'big_inuse') {
                setCurrentFilter(null);
                setFilteredData(lockerData);
                setActiveTab('inventario');
              } else {
                setCurrentFilter('big_inuse');
                handleTabChange('big_inuse');
              }
            }}
            style={{ 
              cursor: 'pointer',
              opacity: currentFilter === 'big_inuse' ? 1 : 0.6 
            }}
          /></Tooltip>, 
          number: bigLockersInUse.length, 
          percentage: `${percentages.bigInUse}%` 
        }
      ]
    },
    {
      title: "Armários p/ Celulares", 
      rows: [
        { 
          icon: <Tooltip title={'LIVRES'}><IconDeviceMobile 
            size="30" 
            color="green" 
            onClick={() => {
              if (currentFilter === 'small_available') {
                setCurrentFilter(null);
                setFilteredData(lockerData);
                setActiveTab('inventario');
              } else {
                setCurrentFilter('small_available');
                handleTabChange('small_available');
              }
            }}
            style={{ 
              cursor: 'pointer',
              opacity: currentFilter === 'small_available' ? 1 : 0.6 
            }}
          /></Tooltip>, 
          number: smallLockersAvailable.length, 
          percentage: `${percentages.smallAvailable}%` 
        },
        { 
          icon: <Tooltip title={'OCUPADOS'}><IconDeviceMobile 
            size="30" 
            color="red" 
            onClick={() => {
              if (currentFilter === 'small_inuse') {
                setCurrentFilter(null);
                setFilteredData(lockerData);
                setActiveTab('inventario');
              } else {
                setCurrentFilter('small_inuse');
                handleTabChange('small_inuse');
              }
            }}
            style={{ 
              cursor: 'pointer',
              opacity: currentFilter === 'small_inuse' ? 1 : 0.6 
            }}
          /></Tooltip>, 
          number: smallLockersInUse.length, 
          percentage: `${percentages.smallInuse}%` 
        }
      ]
    }
  ];

  const columns = [
    {
      title: "Tamanho",
      dataIndex: "size",
      width: 150,
      render: (text) => text?.toUpperCase(),

      sorter: (a, b) => a.size.localeCompare(b.size),
      showSorterTooltip: { title: "Ordenar por tamanho" },
    },
    {
      title: "Armário",
      dataIndex: "codigo",
      render: (text) => text?.toUpperCase(),
      sorter: (a, b) => a.codigo.localeCompare(b.codigo),
      showSorterTooltip: { title: "Ordenar por código" },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
          <Text style={{ 
              color: text === "LIVRE" ? "#52c41a" : // green
                     text === "OCUPADO" ? "#f5222d" : // red
                     "inherit",
                     fontWeight: 'bold' // default color
                    
          }}>
              {text?.toUpperCase()}
          </Text>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      showSorterTooltip: { title: "Ordenar por status" },
  },{
    title: "Colaborador",
    dataIndex: "being_used_by",
    render: (text) => {
        if (!text) return "-"; // Shows dash for null/empty values
        return text.toUpperCase();
    },
    sorter: (a, b) => {
        // Handle null values in sorting
        if (!a.being_used_by) return -1;
        if (!b.being_used_by) return 1;
        return a.being_used_by.localeCompare(b.being_used_by);
    },
    showSorterTooltip: { title: "Ordenar por número do colete" },
},
    {
      title: "Ações",
      width: 100,
      align: "center",
      render: (_, record) => (
        <Dropdown
          menu={{
            items: actionMenuItems,
            onClick: ({ key }) => {
              handleMenuClick(key, record);
            },
          }}
          trigger={["click"]}
          onOpenChange={() => {
            handleMenuClick(_, record);
          }}
        >
          <MoreOutlined style={{ cursor: "pointer", fontSize: "18px" }} />
        </Dropdown>
      ),
    },
  ];

  const actionMenuItems = [
    {
      key: "1",
      label: "Informações",
      icon: <InfoCircleOutlined />,
    },
    {
      key: "2",
      label: "Editar",
      icon: <EditOutlined />,
      disabled: true,
    },
    {
      key: "3",
      label: "Qrcode",
      icon: <QrcodeOutlined />,
    },
    {
      key: "4",
      label: (
        <Popconfirm
          title="Tem certeza?"
          description={
            selectedLocker
              ? `Tem certeza que deseja disponibilizar o equipamento ${selectedLocker.equipment_code}?`
              : "Tem certeza que deseja disponibilizar o equipamento?"
          }
          onConfirm={(e) => {
            e.stopPropagation(); // Prevent menu from closing
            handleUpdateStatus(selectedLocker, "available");
          }}
          okText="Sim"
          cancelText="Não"
          placement="left"
          onCancel={(e) => {
            e.stopPropagation();
          }}
          arrow={false}
          align={{ offset: [0, -120] }}
        >
          <span>Disponibilizar</span>
        </Popconfirm>
      ),
      icon: <CheckCircleOutlined />,
      disabled: true,
    },
    {
      key: "5",
      label: (
        <Popconfirm
          title="Tem certeza?"
          description={
            selectedLocker
              ? `Tem certeza que deseja inativar o equipamento ${selectedLocker.equipment_code}?`
              : "Tem certeza que deseja inativar o equipamento?"
          }
          onConfirm={(e) => {
            e.stopPropagation(); // Prevent menu from closing
            handleUpdateStatus(selectedLocker, "inactive");
          }}
          okText="Sim"
          cancelText="Não"
          placement="left"
          onCancel={(e) => {
            e.stopPropagation();
          }}
          arrow={false}
          align={{ offset: [0, -150] }}
        >
          <span>Inativar</span>
        </Popconfirm>
      ),
      icon: <WarningOutlined />,
      disabled: true,
    },
    {
      key: "6",
      label: (
        <Popconfirm
          title="Tem certeza?"
          description={
            selectedLocker
              ? `Tem certeza que deseja deletar o equipamento ${selectedLocker.equipment_code}?`
              : "Tem certeza que deseja deletar o equipamento?"
          }
          onConfirm={(e) => {
            e.stopPropagation(); // Prevent menu from closing
            handleUpdateStatus(selectedLocker, "deleted");
          }}
          okText="Sim"
          cancelText="Não"
          placement="left"
          onCancel={(e) => {
            e.stopPropagation();
          }}
          arrow={false}
          align={{ offset: [0, -180] }}
        >
          <span>Deletar</span>
        </Popconfirm>
      ),
      icon: <DeleteOutlined />,
      disabled: true,
    },
  ];

  //handler para adicionar equipamentos unitários
  const handleAddEquipment = async (formData) => {
    try {
      await insertNewEquipment(formData);
      message.success("Equipamento adicionado com sucesso!");
      setIsModalVisible(false);
      // Refresh data
      await fetchLockers();
    } catch (error) {
      console.error("Erro ao adicionar equipamento:", error);
      message.error("Erro ao adicionar equipamento");
    }
  };

  //handler para adicionar equipamentos em massa
  const handleBatchUpload = async () => {
    try {
      await fetchLockers();
      message.success("Lista de equipamentos atualizada");
    } catch (error) {
      console.error("Erro ao atualizar lista:", error);
      message.error("Erro ao atualizar lista");
    }
  };

  // Add tab change handler
  const handleTabChange = (activeKey) => {
    setActiveTab(activeKey);
    switch (activeKey) {
      case "inventario":
        setFilteredData(lockerData);
        break;
      case "big_available":
        setFilteredData(
          lockerData.filter((item) => item.status === "LIVRE" && item.size === "BOLSA")
        );
        break;
        case "small_available":
            setFilteredData(
              lockerData.filter((item) => item.status === "LIVRE" && item.size === "CELULAR")
            );
            break;
      case "big_inuse":
        setFilteredData(
          lockerData.filter((item) => item.status === "OCUPADO" && item.size === "BOLSA")
        );
        break;
      case "small_inuse":
        setFilteredData(
          lockerData.filter((item) => item.status === "OCUPADO" && item.size === "CELULAR")
        );
        break;
      case "inativos":
        setFilteredData(
          lockerData.filter((item) => item.status === "Inativo")
        );
        break;
      case "12h":
        setFilteredData(
          lockerData.filter(
            (item) =>
              item.status === "Em uso" &&
              item.usage_duration_category === "in_use_12h"
          )
        );
        break;
      case "24h":
        setFilteredData(
          lockerData.filter(
            (item) =>
              item.status === "Em uso" &&
              item.usage_duration_category === "in_use_24h"
          )
        );
        break;
      default:
        setFilteredData(lockerData);
    }
  };

  // search handler function
  const handleSearch = (value) => {
    setSearchText(value);

    if (!value) {
      // If search is empty, show data based on current tab
      handleTabChange(activeTab);
      return;
    }

    // Search in all columns
    const searchResult = lockerData.filter((item) => {
      return Object.values(item).some((val) =>
        val?.toString().toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredData(searchResult);
  };

  const toggleSearch = () => {
    if (searchVisible) {
      setSearchVisible(false);
      setSearchText("");
      setFilteredData(lockerData); // Reset to current tab data
      handleTabChange(activeTab); // Reapply tab filter
    } else {
      setSearchVisible(true);
    }
  };

  const handleMenuClick = (key, record) => {
    setSelectedLocker(record);
    switch (key) {
      case "1":
        setInfoModalVisible(true);
        break;
      case "2":
        setEditModalVisible(true);
        break;
      case "3":
        setQrModalVisible(true);
        break;
      default:
        setSelectedLocker(record);
    }
  };

  // Add edit handler
  const handleAssignLocker = async (formData) => {
    try {
      const response = await assignLocker(formData);
      
      if (response.message === 'success'){    
      notification.success({
        message: `${response.status}` === 'in_use' ? `Armário ${response.locker}, atríbuido com sucesso` : `Armário ${response.locker}, liberado com sucesso`,
        placement: "topRight",
        showProgress: true,
        duration: 3,
        pauseOnHover: true,
      });
      await fetchLockers(); // Refresh data
      } else {
        notification.error({
          message: `${response.status}` === 'in_use' ? `Houve um erro ao atribuir o armário ${response.locker}` : `Houve um erro ao liberar o armário ${response.locker}`,
          placement: "topRight",
          duration: 3,
          pauseOnHover: true,
          showProgress: true,
        });
        await fetchLockers(); // Refresh data
      }
    } catch (error) {
      console.error("Erro ao atribuir armário:", error);
      notification.error({
        message: `Ops! Algo deu errado. Tente novamente mais tarde!`,
        description: error.message,
        placement: "top",
        duration: 3,
        pauseOnHover: true,
        showProgress: true,
      });
    }
  };

  const handleUpdateStatus = async (equipment, status) => {
    const equipamento = equipment.equipment_id;
    const statusAnterior = selectedLocker.equipment_status;
    try {
      await updateEquipmentStatus(equipamento, status, statusAnterior);
      notification.success({
        message: `${
          status === "available"
            ? `Equipamento ${equipment.equipment_code} pronto para uso!`
            : `${
                status === "inactive"
                  ? `Equipamento ${equipamento} inativado!`
                  : `Equipamento ${equipamento} removido!`
              }`
        }`,
        placement: "top",
        showProgress: true,
        autoClose: 3,
      });
      await fetchLockers(); // Refresh data
    } catch (error) {
      console.error("Erro ao disponibilizar equipamento:", error);
      message.error("Erro ao disponibilizar equipamento");
    }
  };

  const exportToExcel = () => {
    // Prepare data for export
    const exportData = lockerData.map((item) => ({
      Código: item.codigo,
      Status: item.status,
      Tamanho: item.size,
      'Colete': item.being_used_by,
      "Última Atualização": new Date(item.updated_at).toLocaleString(),
    }));

    // Create worksheet
    const ws = XLSX.utils.json_to_sheet(exportData);

    // Create workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Inventário");

    // Generate filename with current date
    const date = new Date().toISOString().split("T")[0];
    const fileName = `inventario_equipamentos_${date}.xlsx`;

    // Save file
    XLSX.writeFile(wb, fileName);
  };

  return (
    <Layout className="equipment-manager-layout">
      <Header className="equipment-header">
        <div className="header-left">
          <MenuOutlined className="menu-icon" onClick={showDrawer} />
          <Text className="header-title">Guarda-Volumes</Text>
        </div>
        <div className="header-right">
          <Tooltip title="Minimizar">
            <MinusOutlined
              className="action-icon"
              onClick={() => navigate("/modules")}
            />
          </Tooltip>
          <Tooltip title="Sair">
            <CloseOutlined
              className="action-icon"
              onClick={() => userLogout()}
            />
          </Tooltip>
        </div>
      </Header>

      <Content className="equipment-content">
      <Row className="card-row" justify="space-between" align="center">
  {cardData.map((card, index) => (
    <React.Fragment key={index}>
      <Col flex="1" className="card-col">
        <Card className="summary-card">
          <Text className="card-title">{card.title}</Text>
          <div className="card-content">
            {card.rows.map((row, rowIndex) => (
              <div key={rowIndex} className="card-row-content" style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px', fontSize: '16px' }}>{row.icon}</span>
                <Text className="card-number">{row.number}</Text>
                <Text
                  className={`card-percentage ${
                    row.percentage.startsWith("-") ? "negative" : "positive"
                  }`}
                >
                  {row.percentage}
                </Text>
              </div>
            ))}
          </div>
        </Card>
      </Col>
      {index < cardData.length - 1 && <div className="card-divider" />}
    </React.Fragment>
  ))}
</Row>

        <div className="search-section">
        <Input.Search
                placeholder="Buscar armário..."
                allowClear
                enterButton
                size="large"
                className="search-input"
                onChange={(e) => handleSearch(e.target.value)}
                onSearch={handleSearch}
                value={searchText}
                onBlur={() => triggerFocus} // Close if empty on blur
                onCancel={toggleSearch} // Close when clicking the clear icon
                autoFocus
              />
                            <Tooltip title="Exportar Inventário">
              <Button
                type="text"
                icon={<FileDoneOutlined />}
                className="icon-button"
                onClick={exportToExcel}
              />
            </Tooltip>
        </div>
        <div style={{ marginBottom: "20px" }}></div>
        <Table
          dataSource={filteredData}
          columns={columns}
          loading={loading}
          onRow={(record) => ({
            onDoubleClick: () => {
              setSelectedLocker(record);
              setAssignLockerModalVisible(true);
            },
            style: { cursor: 'pointer' } // Optional: shows clickable cursor on hover
          })}
          pagination={{
            pageSize: pageSize,
            showSizeChanger: true,
            pageSizeOptions: ["25", "50", "100", "500"],
            showTotal: (total) => `Total ${total} itens`,
            onChange: (_, size) => {
              setPageSize(size);
            },
            onShowSizeChange: (_, size) => {
              if (size === "all") {
                setPageSize(lockerData.length);
              } else {
                setPageSize(Number(size));
              }
            },
          }}
          rowKey={(record) => record.id}
          sticky={true}
        />
      </Content>

      <DrawerMenu open={drawerVisible} onClose={closeDrawer} title="Menu">
        <Text>Conteúdo do Drawer</Text>
      </DrawerMenu>
      <AddEquipmentModal
        open={isModalVisible}
        onCancel={handleCancel}
        onSubmit={handleAddEquipment}
        onBatchSuccess={handleBatchUpload}
      />
      <InfoLockerModal
        open={infoModalVisible}
        onClose={() => {
          setInfoModalVisible(false);
          setSelectedLocker(null);
        }}
        locker={selectedLocker}
      />
      <EditEquipmentModal
        open={editModalVisible}
        onClose={() => {
          setEditModalVisible(false);
          setSelectedLocker(null);
        }}
        onSubmit={handleAssignLocker}
        equipment={selectedLocker}
      />
      <LockerQrCodeModal
        open={qrModalVisible}
        onClose={() => {
          setQrModalVisible(false);
          setSelectedLocker(null);
        }}
        armario={selectedLocker}
      />
      <AssignLockerModal
        open={assignLockerModalVisible}
        onClose={() => {
          setAssignLockerModalVisible(false);
          setSelectedLocker(null);
        }}
        onSubmit={handleAssignLocker}
        armario={selectedLocker}
        />
    </Layout>
  );
};

export default EquipmentManager;
