import React, { useState, useEffect, Suspense } from "react";
import {
  Layout,
  Typography,
  Tooltip,
  Card,
  Row,
  Col,
  Tabs,
  Space,
  Button,
  Table,
  Dropdown,
  message,
  Input,
  Popconfirm,
  notification,
  Spin,
} from "antd";
import {
  MenuOutlined,
  CloseOutlined,
  MinusOutlined,
  MoreOutlined,
  PlusCircleFilled,
  SearchOutlined,
  ScanOutlined,
  FileDoneOutlined,
  QrcodeOutlined,
  EditOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import DrawerMenu from "../components/DrawerMenu"; // Importa o componente reutilizável do drawer
import "./EquipmentManager.css"; // CSS para estilização
import {
  EQUIPMENT_STATUSES,
  fetchAllEquipmentData,
  fetchEquipmentsByStatus,
  insertNewEquipment,
  updateEquipmentStatus,
} from "../services/equipmentService"; // Importa funções de serviço
import { getAccessLevel } from "../services/userServices";
import { userLogout } from "../services/userServices";
import AddEquipmentModal from "../components/AddEquipmentModal";
import InfoEquipmentModal from "../components/InfoEquipmentModal";

import { useNavigate } from "react-router-dom";
import QRCodeModal from "../components/QRCodeModal";
import * as XLSX from "xlsx";
import socket from "../utils/socket";

const { Header, Content } = Layout;
const { Text } = Typography;
const { TabPane } = Tabs;
const EditEquipmentModal = React.lazy(() =>
  import("../components/EditEquipmentModal")
);

const EquipmentManager = () => {
  const navigate = useNavigate(); // Hook do React Router para navegação

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [activeTab, setActiveTab] = useState("");
  const [equipmentData, setEquipmentData] = useState([]); // Estado para equipamentos
  const [loading, setLoading] = useState(false);
  const [equipmentsInUse, setEquipmentsInUse] = useState([]); // Estado para equipamentos
  const [equipmentsAvailable, setEquipmentsAvailable] = useState([]); // Estado para equipamentos
  const [equipmentsMaintenance, setEquipmentsMaintenance] = useState([]); // Estado para equipamentos
  const [equipmentsInavctive, setEquipmentsInactive] = useState([]); // Estado para equipamentos
  const [equipments12h, setEquipments12h] = useState([]); // Estado para equipamentos
  const [equipments24h, setEquipments24h] = useState([]); // Estado para equipamentos
  const [percentages, setPercentages] = useState({
    inUse: 0,
    available: 0,
    maintenance: 0,
    inactive: 0,
    twelvehours: 0,
    twentyfourhours: 0,
  }); // Estado para porcentagens
  const [pageSize, setPageSize] = useState(25);
  const [filteredData, setFilteredData] = useState([]);
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [qrModalVisible, setQrModalVisible] = useState(false);
  const [accessLevel, setAccessLevel] = useState(null);

  const showDrawer = () => setDrawerVisible(true);
  const closeDrawer = () => setDrawerVisible(false);
  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);

  const fetchAllEquipments = async () => {
    setLoading(true);
    try {
      const equipments = await fetchAllEquipmentData();
      setEquipmentData(equipments);
      setFilteredData(equipments); // Atualiza filteredData assim que equipmentData é carregado.

      const [inUseEquip, availableEquip, maintenanceEquip, inactiveEquip] =
        await Promise.all([
          fetchEquipmentsByStatus(EQUIPMENT_STATUSES.IN_USE),
          fetchEquipmentsByStatus(EQUIPMENT_STATUSES.AVAILABLE),
          fetchEquipmentsByStatus(EQUIPMENT_STATUSES.MAINTENANCE),
          fetchEquipmentsByStatus(EQUIPMENT_STATUSES.INACTIVE),
        ]);

      const equipments12h = equipments.filter(
        (equip) =>
          equip.usage_duration_category === "in_use_12h" &&
          equip.equipment_status === "Em uso"
      );
      const equipments24h = equipments.filter(
        (equip) =>
          equip.usage_duration_category === "in_use_24h" &&
          equip.equipment_status === "Em uso"
      );

      setEquipmentsInUse(inUseEquip);
      setEquipmentsAvailable(availableEquip);
      setEquipmentsMaintenance(maintenanceEquip);
      setEquipmentsInactive(inactiveEquip);
      setEquipments12h(equipments12h);
      setEquipments24h(equipments24h);

      const total = equipments.length;
      setPercentages({
        inUse: ((inUseEquip.length / total) * 100).toFixed(0),
        available: ((availableEquip.length / total) * 100).toFixed(0),
        maintenance: ((maintenanceEquip.length / total) * 100).toFixed(0),
        inactive: ((inactiveEquip.length / total) * 100).toFixed(0),
        twelvehours: ((equipments12h.length / total) * 100).toFixed(0),
        twentyfourhours: ((equipments24h.length / total) * 100).toFixed(0),
      });

      const accessLevel = await getAccessLevel();
      setAccessLevel(accessLevel);
    } catch (error) {
      console.error("Erro ao buscar equipamentos:", error);
      message.error("Erro ao buscar equipamentos");
    } finally {
      setLoading(false);
      setActiveTab("inventario");
    }
  };

  //useeffect to retrieve equipments on page load
  useEffect(() => {
    fetchAllEquipments();
  }, []);

  useEffect(() => {
    socket.on("equipmentTrasaction", (data) => {
      if (data.success === true) {
        fetchAllEquipments();
      }
    });
    // Cleanup on unmount
    return () => {
      socket.off("equipmentValidated");
    };
  }, []);

  const cardData = [
    { title: "Total", number: equipmentData.length, percentage: "100%" },
    {
      title: "Disponíveis",
      number: equipmentsAvailable.length,
      percentage: `${percentages.available}%`,
    },
    {
      title: "Em uso",
      number: equipmentsInUse.length,
      percentage: `${percentages.inUse}%`,
    },
    {
      title: "Danificados",
      number: equipmentsMaintenance.length,
      percentage: `${percentages.maintenance}%`,
    },
    {
      title: "Inativos",
      number: equipmentsInavctive.length,
      percentage: `${percentages.inactive}%`,
    },
    {
      title: "+12h em uso",
      number: equipments12h.length,
      percentage: `${percentages.twelvehours}%`,
    },
    {
      title: "+24h em uso",
      number: equipments24h.length,
      percentage: `${percentages.twentyfourhours}%`,
    },
  ];

  const columns = [
    {
      title: "Status",
      dataIndex: "equipment_status",
      render: (text) => text?.toUpperCase(),
      sorter: (a, b) => a.equipment_status.localeCompare(b.equipment_status),
      showSorterTooltip: { title: "Ordenar por status" },
    },
    {
      title: "Código",
      dataIndex: "equipment_code",
      render: (text) => text?.toUpperCase(),
      sorter: (a, b) => a.equipment_code.localeCompare(b.equipment_code),
      showSorterTooltip: { title: "Ordenar por código" },
    },
    {
      title: "Equipamento",
      dataIndex: "equipment_type",
      render: (text) => text?.toUpperCase(),

      sorter: (a, b) => a.equipment_type.localeCompare(b.equipment_type),
      showSorterTooltip: { title: "Ordenar por equipamentos" },
    },
    {
      title: "Último Usuário",
      dataIndex: "last_used_by",
      render: (text) => text?.toUpperCase(),
      sorter: (a, b) => a.last_used_by.localeCompare(b.last_used_by),
      showSorterTooltip: { title: "Ordenar por último usuário" },
    },
    {
      title: "Data e Hora",
      dataIndex: "last_used_at",
      render: (text) => text?.toUpperCase(),
      sorter: (a, b) => new Date(a.last_used_at) - new Date(b.last_used_at),
      showSorterTooltip: { title: "Ordenar por data e hora" },
    },
    {
      title: "Setor",
      dataIndex: "equipment_department",
      render: (text) => text?.toUpperCase(),
      sorter: (a, b) => a.equipment_sector.localeCompare(b.equipment_sector),
      showSorterTooltip: { title: "Ordenar por setor" },
    },
    {
      title: "Nº Série",
      dataIndex: "equipment_serial_number",
      render: (text) => text?.toUpperCase(),
      sorter: (a, b) =>
        a.equipment_serial_number.localeCompare(b.equipment_serial_number),
      showSorterTooltip: { title: "Ordenar por Nº de série" },
    },
    {
      title: "Nº Patrimônio",
      dataIndex: "equipment_patrimony",
      render: (text) => text?.toUpperCase(),
      sorter: (a, b) =>
        a.equipment_patrimony.localeCompare(b.equipment_patrimony),
      showSorterTooltip: { title: "Ordenar por Nº de patrimônio" },
    },
    {
      title: "Ações",
      render: (_, record) => (
        <Dropdown
          menu={{
            items: actionMenuItems,
            onClick: ({ key }) => {
              handleMenuClick(key, record);
            },
          }}
          trigger={["click"]}
          onOpenChange={() => {
            handleMenuClick(_, record);
          }}
        >
          <MoreOutlined style={{ cursor: "pointer", fontSize: "18px" }} />
        </Dropdown>
      ),
    },
  ];

  const actionMenuItems = [
    {
      key: "1",
      label: "Informações",
      icon: <InfoCircleOutlined />,
    },
    {
      key: "2",
      label: "Editar",
      icon: <EditOutlined />,
      disabled:
        accessLevel === "user" ||
        selectedEquipment?.equipment_status === "Inativo" ||
        selectedEquipment?.equipment_status === "Danificado" ||
        selectedEquipment?.equipment_status === "Em uso",
    },
    {
      key: "3",
      label: "Qrcode",
      icon: <QrcodeOutlined />,
      disabled: accessLevel === "user",
    },
    {
      key: "4",
      label: (
        <Popconfirm
          title="Tem certeza?"
          description={
            selectedEquipment
              ? `Tem certeza que deseja disponibilizar o equipamento ${selectedEquipment.equipment_code}?`
              : "Tem certeza que deseja disponibilizar o equipamento?"
          }
          onConfirm={(e) => {
            e.stopPropagation(); // Prevent menu from closing
            handleUpdateStatus(selectedEquipment, "available");
          }}
          okText="Sim"
          cancelText="Não"
          placement="left"
          onCancel={(e) => {
            e.stopPropagation();
          }}
          arrow={false}
          align={{ offset: [0, -120] }}
        >
          <span>Disponibilizar</span>
        </Popconfirm>
      ),
      icon: <CheckCircleOutlined />,
      disabled:
        accessLevel === "user" ||
        selectedEquipment?.equipment_status === "Em uso" ||
        selectedEquipment?.equipment_status === "Disponível",
    },
    {
      key: "5",
      label: (
        <Popconfirm
          title="Tem certeza?"
          description={
            selectedEquipment
              ? `Tem certeza que deseja inativar o equipamento ${selectedEquipment.equipment_code}?`
              : "Tem certeza que deseja inativar o equipamento?"
          }
          onConfirm={(e) => {
            e.stopPropagation(); // Prevent menu from closing
            handleUpdateStatus(selectedEquipment, "inactive");
          }}
          okText="Sim"
          cancelText="Não"
          placement="left"
          onCancel={(e) => {
            e.stopPropagation();
          }}
          arrow={false}
          align={{ offset: [0, -150] }}
        >
          <span>Inativar</span>
        </Popconfirm>
      ),
      icon: <WarningOutlined />,
      disabled:
        accessLevel === "user" ||
        selectedEquipment?.equipment_status === "Em uso" ||
        selectedEquipment?.equipment_status === "Inativo",
    },
    {
      key: "6",
      label: (
        <Popconfirm
          title="Tem certeza?"
          description={
            selectedEquipment
              ? `Tem certeza que deseja deletar o equipamento ${selectedEquipment.equipment_code}?`
              : "Tem certeza que deseja deletar o equipamento?"
          }
          onConfirm={(e) => {
            e.stopPropagation(); // Prevent menu from closing
            handleUpdateStatus(selectedEquipment, "deleted");
          }}
          okText="Sim"
          cancelText="Não"
          placement="left"
          onCancel={(e) => {
            e.stopPropagation();
          }}
          arrow={false}
          align={{ offset: [0, -180] }}
        >
          <span>Deletar</span>
        </Popconfirm>
      ),
      icon: <DeleteOutlined />,
      disabled:
        accessLevel === "user" ||
        selectedEquipment?.equipment_status === "Em uso",
    },
  ];

  //handler para adicionar equipamentos unitários
  const handleAddEquipment = async (formData) => {
    try {
      await insertNewEquipment(formData);
      message.success("Equipamento adicionado com sucesso!");
      setIsModalVisible(false);
      // Refresh data
      await fetchAllEquipments();
    } catch (error) {
      console.error("Erro ao adicionar equipamento:", error);
      message.error("Erro ao adicionar equipamento");
    }
  };

  //handler para adicionar equipamentos em massa
  const handleBatchUpload = async () => {
    try {
      await fetchAllEquipments();
      message.success("Lista de equipamentos atualizada");
    } catch (error) {
      console.error("Erro ao atualizar lista:", error);
      message.error("Erro ao atualizar lista");
    }
  };

  // Add tab change handler
  const handleTabChange = (activeKey) => {
    setActiveTab(activeKey);
    switch (activeKey) {
      case "inventario":
        setFilteredData(equipmentData);
        break;
      case "disponiveis":
        setFilteredData(
          equipmentData.filter((item) => item.equipment_status === "Disponível")
        );
        break;
      case "em-uso":
        setFilteredData(
          equipmentData.filter((item) => item.equipment_status === "Em uso")
        );
        break;
      case "danificados":
        setFilteredData(
          equipmentData.filter((item) => item.equipment_status === "Danificado")
        );
        break;
      case "inativos":
        setFilteredData(
          equipmentData.filter((item) => item.equipment_status === "Inativo")
        );
        break;
      case "12h":
        setFilteredData(
          equipmentData.filter(
            (item) =>
              item.equipment_status === "Em uso" &&
              item.usage_duration_category === "in_use_12h"
          )
        );
        break;
      case "24h":
        setFilteredData(
          equipmentData.filter(
            (item) =>
              item.equipment_status === "Em uso" &&
              item.usage_duration_category === "in_use_24h"
          )
        );
        break;
      default:
        setFilteredData(equipmentData);
    }
  };

  // search handler function
  const handleSearch = (value) => {
    setSearchText(value);

    if (!value) {
      // If search is empty, show data based on current tab
      handleTabChange(activeTab);
      return;
    }

    // Search in all columns
    const searchResult = equipmentData.filter((item) => {
      return Object.values(item).some((val) =>
        val?.toString().toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredData(searchResult);
  };

  const toggleSearch = () => {
    if (searchVisible) {
      setSearchVisible(false);
      setSearchText("");
      setFilteredData(equipmentData); // Reset to current tab data
      handleTabChange(activeTab); // Reapply tab filter
    } else {
      setSearchVisible(true);
    }
  };

  const handleMenuClick = (key, record) => {
    setSelectedEquipment(record);
    switch (key) {
      case "1":
        setInfoModalVisible(true);
        break;
      case "2":
        setEditModalVisible(true);
        break;
      case "3":
        setQrModalVisible(true);
        break;
      default:
        setSelectedEquipment(record);
    }
  };

  // Add edit handler
  const handleEditEquipment = async (id, formData) => {
    try {
      //await updateEquipment(id, formData);
      notification.success({
        message: "Equipamento atualizado com sucesso",
        placement: "topRight",
        showProgress: true,
        duration: 3,
      });
      await fetchAllEquipments(); // Refresh data
    } catch (error) {
      console.error("Erro ao atualizar equipamento:", error);
      notification.error({
        message: "Erro ao atualizar equipamento",
        description: error.message,
        placement: "top",
      });
    }
  };

  const handleUpdateStatus = async (equipment, status) => {
    const equipamento = equipment.equipment_id;
    const statusAnterior = selectedEquipment.equipment_status;
    try {
      await updateEquipmentStatus(equipamento, status, statusAnterior);
      notification.success({
        message: `${
          status === "available"
            ? `Equipamento ${equipment.equipment_code} pronto para uso!`
            : `${
                status === "inactive"
                  ? `Equipamento ${equipamento} inativado!`
                  : `Equipamento ${equipamento} removido!`
              }`
        }`,
        placement: "top",
        showProgress: true,
        autoClose: 3,
      });
      await fetchAllEquipments(); // Refresh data
    } catch (error) {
      console.error("Erro ao disponibilizar equipamento:", error);
      message.error("Erro ao disponibilizar equipamento");
    }
  };

  const exportToExcel = () => {
    // Prepare data for export
    const exportData = equipmentData.map((item) => ({
      Código: item.equipment_code,
      Status: item.equipment_status,
      Tipo: item.equipment_type,
      Descrição: item.equipment_description,
      Modelo: item.equipment_model,
      Fabricante: item.equipment_manufacturer,
      "Nº Série": item.equipment_serial_number,
      "Nº Patrimônio": item.equipment_patrimony,
      Setor: item.equipment_sector,
      "Último Usuário": item.last_used_by,
      "Última Utilização": item.last_used_at,
    }));

    // Create worksheet
    const ws = XLSX.utils.json_to_sheet(exportData);

    // Create workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Inventário");

    // Generate filename with current date
    const date = new Date().toISOString().split("T")[0];
    const fileName = `inventario_equipamentos_${date}.xlsx`;

    // Save file
    XLSX.writeFile(wb, fileName);
  };

  return (
    <Layout className="equipment-manager-layout">
      <Header className="equipment-header">
        <div className="header-left">
          <MenuOutlined className="menu-icon" onClick={showDrawer} />
          <Text className="header-title">Gerenciador de Equipamentos</Text>
        </div>
        <div className="header-right">
          <Tooltip title="Minimizar">
            <MinusOutlined
              className="action-icon"
              onClick={() => navigate("/modules")}
            />
          </Tooltip>
          <Tooltip title="Sair">
            <CloseOutlined
              className="action-icon"
              onClick={() => userLogout()}
            />
          </Tooltip>
        </div>
      </Header>

      <Content className="equipment-content">
        <Row className="card-row" justify="space-between" align="center">
          {cardData.map((card, index) => (
            <React.Fragment key={index}>
              <Col flex="1" className="card-col">
                <Card className="summary-card">
                  <Text className="card-title">{card.title}</Text>
                  <div className="card-content">
                    <Text className="card-number">{card.number}</Text>
                    <Text
                      className={`card-percentage ${
                        card.percentage.startsWith("-")
                          ? "negative"
                          : "positive"
                      }`}
                    >
                      {card.percentage}
                    </Text>
                  </div>
                </Card>
              </Col>
              {index < cardData.length - 1 && <div className="card-divider" />}
            </React.Fragment>
          ))}
        </Row>

        <div className="tabs-section">
          <Row>
            <Col className="tabs-col">
              <div className="col-div">
                <Tabs
                  activeKey={activeTab}
                  onChange={handleTabChange}
                  className="tabs-style"
                  tabBarStyle={{ margin: 0 }}
                >
                  <TabPane tab="Inventário" key="inventario" />
                  <TabPane tab="Disponíveis" key="disponiveis" />
                  <TabPane tab="Em uso" key="em-uso" />
                  <TabPane tab="Danificados" key="danificados" />
                  <TabPane tab="Inativos" key="inativos" />
                  <TabPane tab="+12h" key="12h" />
                  <TabPane tab="+24h" key="24h" />
                </Tabs>
                <Space className="tab-buttons">
                  <Tooltip title="Adicionar Equipamentos">
                    <Button
                      icon={<PlusCircleFilled />}
                      className="add-button"
                      onClick={showModal}
                      disabled={accessLevel === "user"}
                    >
                      Adicionar
                    </Button>
                  </Tooltip>
                  <Tooltip title="Realizar entrega/devolução">
                    <Button
                      icon={<ScanOutlined />}
                      className="scan-button"
                      onClick={() => navigate("/deliveryequipamentos")}
                    >
                      Leitura Digital
                    </Button>
                  </Tooltip>
                </Space>
              </div>
            </Col>
          </Row>
          <div className="right-buttons">
            {searchVisible ? (
              <Input.Search
                placeholder="Buscar equipamento..."
                allowClear
                enterButton
                size="large"
                className="search-input"
                onChange={(e) => handleSearch(e.target.value)}
                onSearch={handleSearch}
                value={searchText}
                onBlur={() => !searchText && toggleSearch()} // Close if empty on blur
                onCancel={toggleSearch} // Close when clicking the clear icon
                autoFocus
              />
            ) : (
              <Tooltip title="Buscar">
                <Button
                  type="text"
                  icon={<SearchOutlined />}
                  className="icon-button"
                  onClick={toggleSearch}
                />
              </Tooltip>
            )}
            <Tooltip title="Exportar Inventário">
              <Button
                type="text"
                icon={<FileDoneOutlined />}
                className="icon-button"
                onClick={exportToExcel}
              />
            </Tooltip>
          </div>
        </div>
        <div style={{ marginBottom: "20px" }}></div>
        <Table
          dataSource={filteredData}
          columns={columns}
          loading={loading}
          pagination={{
            pageSize: pageSize,
            showSizeChanger: true,
            pageSizeOptions: ["25", "50", "100", "500"],
            showTotal: (total) => `Total ${total} itens`,
            onChange: (_, size) => {
              setPageSize(size);
            },
            onShowSizeChange: (_, size) => {
              if (size === "all") {
                setPageSize(equipmentData.length);
              } else {
                setPageSize(Number(size));
              }
            },
          }}
          rowKey={(record) => record.equipment_id}
          sticky={true}
        />
      </Content>

      <DrawerMenu open={drawerVisible} onClose={closeDrawer} title="Menu">
        <Text>Conteúdo do Drawer</Text>
      </DrawerMenu>
      <AddEquipmentModal
        open={isModalVisible}
        onCancel={handleCancel}
        onSubmit={handleAddEquipment}
        onBatchSuccess={handleBatchUpload}
      />
      <InfoEquipmentModal
        open={infoModalVisible}
        onClose={() => {
          setInfoModalVisible(false);
          setSelectedEquipment(null);
        }}
        equipment={selectedEquipment}
      />
      <React.Suspense fallback={<Spin indicator={<LoadingOutlined spin />} />}>
        <EditEquipmentModal
          open={editModalVisible}
          onClose={() => {
            setEditModalVisible(false);
            setSelectedEquipment(null);
          }}
          onSubmit={handleEditEquipment}
          equipment={selectedEquipment}
        />
      </React.Suspense>
      <QRCodeModal
        open={qrModalVisible}
        onClose={() => {
          setQrModalVisible(false);
          setSelectedEquipment(null);
        }}
        equipment={selectedEquipment}
      />
    </Layout>
  );
};

export default EquipmentManager;
