import api from "./api";

export const LOCKER_STATUSES = {
  IN_USE: "in_use",
  AVAILABLE: "available",
  DELETED: "deleted",
  INACTIVE: "inactive",
};

//fetchall lockers
export const fetchAllLockers = async () => {
  const token = localStorage.getItem("token");
  const response = await api.get("/lockers", {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data.lockers;
};

export const fetchLockerByStatus = async (status) => {
  const token = localStorage.getItem("token");
  try {
    const response = await api.get(`/lockers/${status}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.status;
  } catch (error) {
    console.error(error);
    console.log(`${error.response.data.message} com o status ${status}`);
    return [];
  }
};

export const assignLocker = async (data) => {
  const token = localStorage.getItem("token");
  try {
    const response = await api.post("/assignlocker", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchLockerHistory = async (lockerId, startDate, endDate) => {
  const token = localStorage.getItem("token");
  const response = await api.get(`/lockers/${lockerId}/history`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};
