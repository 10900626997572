import { io } from "socket.io-client";

// Usar caminho relativo para produção e fallback para localhost no desenvolvimento
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || "/socket.io/";

export const socket = io(SOCKET_URL, {
  withCredentials: true,
  transports: ["websocket"],
});

export default socket;
