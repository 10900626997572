import api from './api';

export const refreshToken = async () => {
    try {
        const oldToken = localStorage.getItem('token');
        const response = await api.post('/refresh-token', {
            token: oldToken
        });
        return response.data.token;
    } catch (error) {
        throw error;
    }
};