import api from "./api";

// Constants
export const EQUIPMENT_STATUSES = {
  IN_USE: "in_use",
  AVAILABLE: "available",
  MAINTENANCE: "damaged",
  INACTIVE: "inactive",
};

// Helper function for API calls
export const fetchEquipmentsByStatus = async (status) => {
  const token = localStorage.getItem("token");
  try {
    const response = await api.get(`/equipments/${status}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.status;
  } catch (error) {
    console.error(`${error.response.data.message} com o status ${status}`);
    return [];
  }
};

// Fetch all equipment data
export const fetchAllEquipmentData = async () => {
  const token = localStorage.getItem("token");
  const response = await api.get("/equipments", {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data.equipments;
};

// insert new equipment
export const insertNewEquipment = async (equipmentData) => {
  const token = localStorage.getItem("token");
  const response = await api.post("/addequipments", equipmentData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// Insert batch equipment via file
export const insertBatchEquipment = async (file) => {
  const token = localStorage.getItem("token");

  // Criar FormData para envio do arquivo
  const formData = new FormData();
  formData.append("file", file);

  const response = await api.post("/addequipments/batch", formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data", // Necessário para envio de arquivos
    },
  });

  return response.data;
};

export const validateEquipmentCodeService = async (code) => {
  const token = localStorage.getItem("token");

  const response = await api.get(`/equipments/validate/${code}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const success = response.data.success;

  if (success === false) {
    throw new Error("Equipamento não encontrado");
  }
  return response.data;
};

export const validateEquipmentEmployeeService = async (badge) => {
  const token = localStorage.getItem("token");

  const response = await api.get(`/employees/validate/${badge}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const success = response.data.success;

  if (success === false) {
    throw new Error("Colaborador não encontrado");
  }

  return response.data;
};

export const newEquipmentTransaction = async (transactionData) => {
  const token = localStorage.getItem("token");

  // Enviar FormData diretamente
  const response = await api.post("/equipment/transaction", transactionData, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });

  if (!response.data.success) {
    throw new Error(response.data.message || "Erro na transação");
  }

  return response.data;
};

export const fetchEquipmentHistory = async (
  equipmentId,
  startDate,
  endDate
) => {
  const token = localStorage.getItem("token");

  const response = await api.get(`/equipment/${equipmentId}/history`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const updateEquipmentStatus = async (
  equipmentId,
  status,
  statusAnterior
) => {
  const token = localStorage.getItem("token");
  const response = await api.post(
    `/equipment/updatestatus`,
    {
      equipmentId,
      status,
      statusAnterior,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
