import React, { useState } from 'react';
import { Modal, Form, Input, message, Progress, notification } from 'antd';
import api from '../services/api';
import './ChangePasswordModal.css';

const ChangePasswordModal = ({ open, onClose, matricula }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [requirements, setRequirements] = useState({
        length: false,
        uppercase: false,
        number: false,
        symbol: false
    });

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            await api.post('/change-password', {
                senhaAtual: values.currentPassword,
                novaSenha: values.newPassword,
                badge: matricula
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            notification.success({message:'Senha alterada com sucesso! Faça login novamente.'});
            sessionStorage.removeItem('senha');
            onClose();
        } catch (error) {
            notification.error({message: 'Erro ao alterar senha. Tente novamente.'});
            console.log(error)
        } finally {
            setLoading(false);
        }
    };

    const validatePassword = (password) => {
        const checks = {
            length: password.length >= 8,
            uppercase: /[A-Z]/.test(password),
            number: /[0-9]/.test(password),
            symbol: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        };
        
        setRequirements(checks);
        const strength = Object.values(checks).filter(Boolean).length;
        setPasswordStrength((strength / 4) * 100);
        
        return { 
            strength: (strength / 4) * 100,
            checks,
            isValid: strength === 4
        };
    };

    const getRequirementColor = (requirement) => {
        return requirements[requirement] ? '#52c41a' : '#666';
    };

    return (
        <Modal
            title="Alterar Senha"
            open={open}
            onOk={() => form.submit()}
            onCancel={onClose}
            confirmLoading={loading}
            closable={false}
            maskClosable={false}
            keyboard={false}
        >
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                preserve={false}
            >
                <Form.Item
                    name="currentPassword"
                    label="Senha Atual"
                    rules={[
                        { required: true, message: 'Insira sua senha atual' }
                    ]}
                >
                    <Input.Password />
                    </Form.Item>
                    <Form.Item
    name="newPassword"
    label="Nova Senha"
    rules={[
        { required: true, message: 'Insira a nova senha' },
    ]}
>
    <div>
        <Input.Password 
            onChange={(e) => validatePassword(e.target.value)}
        />
        <Progress 
            percent={passwordStrength}
            steps={4}
            strokeColor={['#ff4d4f', '#ff4d4f', '#faad14', '#52c41a']}
            style={{ marginTop: 8}}
            size={{width: 117, height: 5}}
        />
        <div style={{ fontSize: '12px' }}>
            <ul style={{ margin: '4px 0', paddingLeft: '20px', listStyleType: 'none' }}>
                <li style={{ color: getRequirementColor('length') }}>
                    ✓ Mínimo 8 caracteres
                </li>
                <li style={{ color: getRequirementColor('uppercase') }}>
                    ✓ 1 letra maiúscula
                </li>
                <li style={{ color: getRequirementColor('number') }}>
                    ✓ 1 número
                </li>
                <li style={{ color: getRequirementColor('symbol') }}>
                    ✓ 1 símbolo (!@#$%^&*)
                </li>
            </ul>
        </div>
    </div>
</Form.Item>
                <Form.Item
                    name="confirmPassword"
                    label="Confirme a Nova Senha"
                    dependencies={['newPassword']}
                    rules={[
                        { required: true, message: 'Confirme a nova senha' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('As senhas não coincidem!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ChangePasswordModal;