import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ModuleSelection from './pages/ModuleSelection';
import Login from './pages/Login';
import EquipmentManager from './pages/EquipmentManagement';
import EquipmentTransactions from './pages/EquipmentTransactions';
import PrivateRoute from './contexts/PrivateRoute';
import LockerManagement from './pages/LockerManagement';



function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path='/modules' element={
                        <PrivateRoute allowedRoles={['admin', 'superadmin']}>
                            <ModuleSelection />
                        </PrivateRoute>
                    }  />
                <Route path='/equipamentos' element={
                        <PrivateRoute>
                            <EquipmentManager/>
                        </PrivateRoute>
                    } />
                <Route path='/deliveryequipamentos' element={
                        <PrivateRoute>
                            <EquipmentTransactions/>
                        </PrivateRoute>
                    } />
                <Route path='/guardavolumes' element={
                        <PrivateRoute>
                            <LockerManagement/>
                        </PrivateRoute>
                    } />
            </Routes>
        </Router>
    );
}

export default App;