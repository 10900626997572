import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Typography,
  message,
  Divider,
  notification,
} from "antd";
import {
  PaperClipOutlined,
  DownloadOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import "./AddUserModal.css";
import Dragger from "antd/es/upload/Dragger";
import {
  addUser,
  getCollaboratorData,
  insertBatchUsers,
} from "../services/userServices";
import api from "../services/api";

const { Search } = Input;
const { Title, Text } = Typography;

const AddUserModal = ({ open, onCancel, onSubmit, onBatchSuccess, level }) => {
  const [isBatchUpload, setIsBatchUpload] = useState(true);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [user, setUser] = useState([]);
  const [collaborator, setCollaborator] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        return;
      }

      try {
        const userResponse = await api.get("/users", {
          headers: { Authorization: `Bearer ${token}` },
        });
        const modulesResponse = await api.get("/users/modules", {
          headers: { Authorization: `Bearer ${token}` },
        });

        setUser({
          name: userResponse.data.name,
          avatar: "https://via.placeholder.com/150",
          level: userResponse.data.level,
          modules: modulesResponse.data.modules,
        });
        if (userResponse.data.level !== "user") {
          setIsBatchUpload(true);
        } else {
          setIsBatchUpload(false);
        }
      } catch (error) {
        console.error("Erro ao carregar dados:", error);
        //navigate('/login');
      }
    };

    fetchUserData();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (isBatchUpload) {
        const file = form.getFieldValue("batchFile");
        if (!file) {
          message.error("Por favor, selecione um arquivo para upload.");
          return;
        }
        await insertBatchUsers(file);
        notification.success({
          message: "Sucesso!",
          description: "Usuários cadastrados com sucesso!",
        });
        onBatchSuccess?.(); // callback to refresh equipment list
      } else {
        const formData = await form.validateFields();
        const result = await addUser({ user, collaborator, formData });
        console.log(result);
        notification.success({
          message: "Sucesso!",
          description: "Usuário foi cadastrado com sucesso!",
        });
      }
      form.resetFields();
      setFileList([]);
      onCancel();
    } catch (error) {
      console.error("Erro ao adicionar usuário:", error);
      if (error.response.data.message === "Matrícula já cadastrada") {
        notification.error({
          message: "Usuário já cadastrado",
          description: "Por favor, verifique a matrícula e tente novamente",
        });
        form.resetFields();
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCollaboratorSearch = async (value) => {
    try {
      const response = await getCollaboratorData(value);
      setCollaborator(response.data);
      form.setFieldValue("nome", `${response.data.user.nome_completo}`);
      form.setFieldValue("funcao", `${response.data.user.funcao}`);
      form.setFieldValue("secao", `${response.data.user.secao}`);
    } catch (error) {
      console.error(error);
    }
  };

  const userModules = [
    { value: "equipamentos", label: "Equipamentos" },
    { value: "guarda-volumes", label: "Guarda-Volumes" },
  ];

  const userLevels = [
    { value: "user", label: "Usuário comum" },
    { value: "admin", label: "Administrador Local" },
    { value: "superadmin", label: "Administrador Master" },
  ];

  const userAreas = [
    { value: "ceop", label: "CEOP" },
    { value: "spax", label: "SPAX" },
  ];

  const resetForm = () => {
    form.resetFields();
    setSelectedModules([]);
    setSelectedLevels([]);
    setFileList([]);
  };

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  return (
    <Modal
      title={
        <div className="titleDiv">
          <Title level={4} className="modalTitle">
            {isBatchUpload ? "Cadastro em Massa" : "Cadastro Unitário"}
          </Title>
          <Text type="secondary" className="modalSubTitle">
            {isBatchUpload
              ? "Cadastrar diversos usuários de uma só vez"
              : "Cadastrar usuário"}
          </Text>
        </div>
      }
      open={open}
      onCancel={handleCancel}
      onClose={handleCancel}
      afterClose={resetForm}
      footer={[
        <Button key="cancel" onClick={onCancel} className="cancelButton">
          Cancelar
        </Button>,
        <Button
          key="submit"
          onClick={handleSubmit}
          className="sendButton"
          loading={loading}
          disabled={loading}
        >
          Cadastrar
        </Button>,
      ]}
      width={600}
    >
      <div className="divButtonTabs">
        <Button
          className={isBatchUpload ? "btnSelected" : "btnNotSelected"}
          onClick={() => setIsBatchUpload(true)}
          style={{ display: level !== "user" ? "block" : "none" }}
        >
          Cadastro em Massa
        </Button>
        <Button
          className={!isBatchUpload ? "btnSelected" : "btnNotSelected"}
          onClick={() => setIsBatchUpload(false)}
        >
          Cadastro Unitário
        </Button>
      </div>

      {isBatchUpload && user.level !== "user" ? (
        <div>
          <Dragger
            name="batchFile"
            accept=".xlsx"
            fileList={fileList}
            beforeUpload={(file) => {
              form.setFieldsValue({ batchFile: file });
              setFileList([file]); // Update fileList
              message.success(`${file.name} pronto para envio!`);
              return false;
            }}
            onRemove={() => {
              form.setFieldsValue({ batchFile: null });
              setFileList([]); // Clear fileList when file is removed
              return true;
            }}
            multiple={false}
            className="dragDropContainer"
          >
            <FileAddOutlined className="fileAddIcon" />
            <p className="draggerText">
              Arraste e solte seu arquivo aqui ou clique para selecionar
            </p>
            <Button className="uploadButton">Selecionar Arquivo</Button>
          </Dragger>

          <div className="modelDownloadContainer">
            <PaperClipOutlined />
            <Text>
              <a
                href="/public/templates/modelo_importacao_usuarios.xlsx"
                download="modelo_importacao_usuarios.xlsx"
              >
                {" "}
                Baixar o modelo de importação
              </a>
            </Text>
            <DownloadOutlined />
          </div>
        </div>
      ) : (
        <Form form={form} layout="horizontal" preserve={false}>
          <Form.Item
            label="Matrícula"
            name="matricula"
            rules={[{ required: true, message: "Este campo é obrigatório" }]}
            tooltip="Digite a matrícula do colaborador e clique no botão para buscar"
          >
            <Search
              placeholder="Digite a matrícula do usuário e clique em buscar"
              onSearch={handleCollaboratorSearch}
              enterButton
              autoFocus
            />
          </Form.Item>
          <Divider></Divider>

          <Form.Item
            label="Nome"
            name="nome"
            rules={[{ required: true, message: "Este campo é obrigatório" }]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="Função"
            name="funcao"
            rules={[{ required: true, message: "Este campo é obrigatório" }]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="Seção"
            name="secao"
            rules={[{ required: true, message: "Este campo é obrigatório" }]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Áreas Autorizadas"
            name="areas"
            hidden={level !== "superadmin"}
          >
            <Select
              showSearch
              optionFilterProp="label"
              options={userAreas}
              onChange={(value) => setSelectedAreas(value)}
              name={selectedAreas}
              placeholder="Áreas autorizadas"
              allowClear
              disabled={level !== "superadmin"}
            />
          </Form.Item>

          <Form.Item
            label="Módulos Autorizados"
            name="modulos"
            hidden={level !== "superadmin"}
          >
            <Select
              showSearch
              mode="multiple"
              optionFilterProp="label"
              options={userModules}
              onChange={(value) => setSelectedModules(value)}
              name={selectedModules}
              placeholder="Módulos autorizados"
              allowClear
              disabled={level !== "superadmin"}
            />
          </Form.Item>

          <Form.Item
            label="Nível de acesso"
            name="niveldeacesso"
            hidden={level !== "superadmin"}
          >
            <Select
              showSearch
              optionFilterProp="label"
              options={userLevels}
              onChange={(value) => setSelectedLevels(value)}
              name={selectedLevels}
              placeholder="Nível de acesso"
              allowClear
              disabled={level !== "superadmin"}
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default AddUserModal;
