import React, { useState, useEffect, useRef } from 'react';
import { Card, Avatar, Typography, Layout, Dropdown, Menu, Alert, Spin, notification } from 'antd';
import { LogoutOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons';
import './SystemSelection.css';
import logo from '../assets/logos/winglet_logo.png';
import footerLogo from '../assets/logos/powered_by.png';
import api from '../services/api';
import { useNavigate } from 'react-router-dom';
import eqicon from '../assets/icons/equipamentos_module.png';
import pnaeicon from '../assets/icons/pnaeicon.png';
import guardavolumesicon from '../assets/icons/guardavolumesicon.png';
import flightsicon from '../assets/icons/flightsicon.png';
import skyguardianicon from '../assets/icons/skyguardianicon.png';
import gseicon from '../assets/icons/gseicon.png';
import coiicon from '../assets/icons/coiicon.png';
import DrawerMenu from '../components/DrawerMenu';
import { userLogout } from '../services/userServices';

const { Header, Content, Footer } = Layout;
const { Text } = Typography;

const getModuleIcon = (moduleName) => {
    const iconMap = {
        equipamentos: eqicon, // Associa o nome do módulo ao ícone
        pnae: pnaeicon,     // Outro módulo com seu ícone
        'guarda-volumes': guardavolumesicon,     // Outro módulo com seu ícone
        flights: flightsicon,     // Outro módulo com seu ícone
        skyguardian: skyguardianicon,     // Outro módulo com seu ícone
        gse: gseicon,     // Outro módulo com seu ícone
        coi: coiicon,     // Outro módulo com seu ícone
    };

    return iconMap[moduleName] || eqicon; // Retorna um ícone padrão se não houver correspondência
};

const ModuleSelection = () => {
    const [modules, setModules] = useState([]);
    const [user, setUser] = useState({ name: '', avatar: '' });
    const [loading, setLoading] = useState(true);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const notificationShownRef = useRef(false); 

    

    const navigate = useNavigate();

    const showDrawer = () => setDrawerVisible(true);
    const closeDrawer = () => setDrawerVisible(false);

    // Carregar dados do usuário e módulos autorizados
    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/login');
                return;
            }

            try {
                const userResponse = await api.get('/users', { headers: { Authorization: `Bearer ${token}` } });
                const modulesResponse = await api.get('/users/modules', { headers: { Authorization: `Bearer ${token}` } });

                setUser({
                    name: userResponse.data.name,
                    avatar: 'https://via.placeholder.com/150',
                    level: userResponse.data.level,
                    modules: modulesResponse.data.modules
                });
                setModules(modulesResponse.data.modules);
                setLoading(false); // Finaliza o carregamento
                const userFirstName = userResponse.data.name.split(' ')[0];
                if (!notificationShownRef.current) {
                    notification.success({ 
                        message: 'Bem-vindo', 
                        description: `Olá ${userFirstName}, seja bem-vindo de volta!`, 
                        placement: 'top', 
                        duration: 3, 
                        showProgressBar: true 
                    });
                    notificationShownRef.current = true;
                }
            } catch (error) {
                console.error('Erro ao carregar dados:', error);
                navigate('/login');
            }
        };

        fetchUserData();
    }, [navigate]);

    // Menu no avatar
    const menu = (
        <Menu>
            <Menu.Item key="profile" icon={<UserOutlined />} onClick={() => navigate('/profile')}>
                Meu perfil
            </Menu.Item>
            <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={() => {
                userLogout();
            }}>
                Logout
            </Menu.Item>
        </Menu>
    );

    return (
        <Layout className="system-selection-container">

            <Header className="header">
                <div className="header-content">

                    <Dropdown overlay={menu} trigger={['click']}>
                        <Avatar src={user.avatar} size="large" shape="square" className="avatar" />
                    </Dropdown>
                    <MenuOutlined className="menu-icon" onClick={showDrawer} />

                </div>
            </Header>
            <Content className="content">
                <img src={logo} alt="Logo" className="main-logo" />
                <Text className="select-text">Selecione o módulo desejado</Text>
                {loading ? (
                    <Spin size="large" />
                ) : (
                    <div className="card-container">
                        {modules.map((module) => (
                            <Card
                                key={module.id}
                                className="module-card"
                                hoverable
                                onClick={() => navigate(module.path)}
                            >
                                <div className="card-icon">
                                <img src={getModuleIcon(module.name)} alt={module.name} />
                            </div>
                                <Text className="card-text">{module.name}</Text>
                            </Card>
                        ))}
                    </div>
                )}
            </Content>
            <DrawerMenu open={drawerVisible} onClose={closeDrawer} title="Menu">
        <Text>Conteúdo do Drawer</Text>
      </DrawerMenu>
            <Footer className="footer">
                <img src={footerLogo} alt="Footer Logo" className="footer-logo" />
            </Footer>
        </Layout>
    );
};

export default ModuleSelection;
