// src/components/InfoEquipmentModal.js
import React, { useState } from "react";
import {
  Modal,
  Descriptions,
  DatePicker,
  Button,
  Table,
  Space,
  Tooltip,
  Tag,
  notification,
} from "antd";
import Ribbon from "antd/es/badge/Ribbon";
import { FileExcelOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import locale from "antd/es/date-picker/locale/pt_BR";
import { fetchLockerHistory } from "../services/lockerService";

const { RangePicker } = DatePicker;

const InfoLockerModal = ({ open, onClose, locker }) => {
  const [dateRange, setDateRange] = useState(null);
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getStatusColor = (status) => {
    const colors = {
      LIVRE: "green",
      "Em uso": "orange",
      OCUPADO: "red",
      Inativo: "yellow",
      damaged: "red",
      in_use_24h: "red",
      in_use_12h: "red",
    };
    return colors[status] || "default";
  };

  const getStatusText = (status) => {
    const texts = {
      LIVRE: "Livre",
      OCUPADO: "Ocupado",
      damaged: "Danificado",
      inactive: "Inativo",
      in_use_24h: "+24h",
      in_use_12h: "+12h",
    };
    return texts[status] || status;
  };

  const historyColumns = [
    {
      title: "Data/Hora",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (text) => new Date(text).toLocaleString("pt-BR"),
    },
    {
      title: "Ação",
      dataIndex: "action",
      key: "action",
      render: (text) => (
        <Tag color={text === "Ocupado" ? "red" : "green"}>
          {text.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Colete",
      dataIndex: "colete",
      key: "colete",
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: "Responsável",
      dataIndex: "assigned_by",
      key: "assigned_by",
      render: (text, record) => (
        <Tooltip
          title={`${record.processedByName || ""} (${
            record.processedByBadge || ""
          })`}
        >
          <span>{`${record.processedByBadge || ""} - ${
            record.processedByName || ""
          } ${record.processedByLastname}`}</span>
        </Tooltip>
      ),
    },
  ];

  const handleDateRangeChange = async (dates) => {
    setDateRange(dates);
    if (dates) {
      setLoading(true);
      try {
        // Format start date: YYYY-MM-DD 00:00:00
        const startDate = dates[0].format("YYYY-MM-DD");

        // Format end date: YYYY-MM-DD 23:59:59
        const endDate = dates[1].format("YYYY-MM-DD");

        const response = await fetchLockerHistory(
          locker.id,
          startDate,
          endDate
        );

        setHistoryData(response.data);
      } catch (error) {
        console.error("Erro ao buscar histórico:", error);
        if (error.status === 404) {
          notification.error({
            message: "Ops!",
            description:
              "Parece que este equipamento não possui histórico de movimentações",
          });
          setDateRange(null);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const exportToExcel = () => {
    if (historyData.length === 0) return;

    const formattedHistory = historyData.map((row) => ({
      "Data e Hora": new Date(row.timestamp).toLocaleString(),
      Ação: row.action,
      Colete: row.colete,
      Armário: row.armario,
      Responsável: `${row.processedByBadge} - ${row.processedByLastname} ${row.processedByName}`,
    }));

    const ws = XLSX.utils.json_to_sheet(formattedHistory);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Histórico");

    // Gerar nome do arquivo com código do equipamento e data
    const fileName = `historico_armario_${locker.codigo}_${
      new Date().toISOString().split("T")[0]
    }.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  const handleClose = () => {
    // Reset all relevant states
    setHistoryData([]);
    setDateRange(null);
    setLoading(false);
    // Call original onClose prop
    onClose();
  };

  if (!locker) return null;

  return (
    <>
      <Modal
        title={`Detalhes do Armário ${locker.codigo}`}
        open={open}
        onCancel={handleClose}
        footer={null}
        width={800}
      >
        <Ribbon
          text={getStatusText(locker.status)}
          color={getStatusColor(locker.status)}
          placement="end"
          style={{ marginTop: locker.status === "OCUPADO" ? 10 : 10 }} // -5
        >
          <Ribbon
            text={getStatusText(locker.usage_duration_category)}
            color={getStatusColor(locker.usage_duration_category)}
            placement="end"
            style={{
              marginTop: locker.status === "OCUPADO" ? 0 : 0,
              display: locker.status === "OCUPADO" ? "none" : "none",
            }} //0 : 22 e 'none' : 'block'
          >
            <Descriptions bordered column={2}>
              <Descriptions.Item
                label="Armário"
                span={2}
              >{`${locker.codigo}`}</Descriptions.Item>
              <Descriptions.Item label="Tamanho">
                {locker.size}
              </Descriptions.Item>
              {locker.status === "OCUPADO" && (
                <Descriptions.Item label="Colete" span={2}>
                  {locker.being_used_by}
                </Descriptions.Item>
              )}
            </Descriptions>
          </Ribbon>
        </Ribbon>

        <div style={{ marginTop: 24 }}>
          <Space style={{ marginBottom: 16 }}>
            <RangePicker
              locale={locale}
              onChange={handleDateRangeChange}
              style={{ width: 400 }}
              format={"DD/MM/YYYY"}
            />
            <Button
              type="primary"
              icon={<FileExcelOutlined />}
              onClick={exportToExcel}
              disabled={!historyData.length}
            >
              Exportar Excel
            </Button>
          </Space>

          <Table
            columns={historyColumns}
            dataSource={historyData}
            loading={loading}
            pagination={{ pageSize: 5 }}
            rowKey="id"
            size="small"
          />
        </div>
      </Modal>
    </>
  );
};

export default InfoLockerModal;
