import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Typography,
  message,
  AutoComplete,
} from "antd";
import {
  PaperClipOutlined,
  DownloadOutlined,
  FileAddOutlined,
  CheckSquareTwoTone,
} from "@ant-design/icons";
import "./AddEquipmentModal.css";
import Dragger from "antd/es/upload/Dragger";
import { insertBatchEquipment } from "../services/equipmentService";

const { Title, Text } = Typography;

const AddEquipmentModal = ({ open, onCancel, onSubmit, onBatchSuccess }) => {
  const [isBatchUpload, setIsBatchUpload] = useState(true);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedManufacturer, setSelectedManufacturer] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedTypeLabel, setSelectedTypeLabel] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (isBatchUpload) {
        const file = form.getFieldValue("batchFile");
        if (!file) {
          message.error("Por favor, selecione um arquivo para upload.");
          return;
        }
        await insertBatchEquipment(file);
        message.success("Arquivo processado com sucesso!");
        onBatchSuccess?.(); // callback to refresh equipment list
      } else {
        const formData = await form.validateFields();
        await onSubmit(formData);
      }
      form.resetFields();
      setFileList([]);
      onCancel();
    } catch (error) {
      console.error("Erro ao adicionar equipamento:", error);
      message.error("Erro ao processar a solicitação.");
    } finally {
      setLoading(false);
    }
  };

  const equipmentTypes = [
    { value: "Celular", label: "Celular" },
    { value: "Rádio", label: "Rádio" },
    { value: "Vassoura", label: "Vassoura" },
    { value: "Lanterna", label: "Lanterna" },
    { value: "Aspirador", label: "Aspirador" },
    { value: "Borrifador", label: "Borrifador" },
    { value: "Bolsa", label: "Bolsa" },
    { value: "Bateria", label: "Bateria" },
    { value: "Bateria LATAM", label: "Bateria LATAM" },
    { value: "Carregador", label: "Carregador" },
    { value: "Scanner", label: "Scanner" },
    { value: "Cartão", label: "Cartão" },
    { value: "Medidor", label: "Medidor" },
    { value: "Trator Elétrico", label: "Trator Elétrico" },
  ];

  const handleSelect = (value) => {
    // Encontre o label correspondente ao valor selecionado
    const selectedOption = equipmentTypes.find(
      (option) => option.value === value
    );
    if (selectedOption) {
      setSelectedTypeLabel(selectedOption.label);
    }
  };

  const equipmentModels = [
    { value: "A14-5G", label: "A14 5G" },
    { value: "HP-606", label: "HP-606" },
    { value: "PH-600", label: "PH-600" },
    { value: "SEPURA", label: "SEPURA" },
    { value: "STP9240", label: "STP9240" },
    { value: "B-MAX", label: "B-MAX" },
    { value: "A11", label: "A11" },
    { value: "Makita Portátio", label: "Makita Portátil" },
    { value: "POC", label: "POC" },
  ];

  const equipmentManufacturers = [
    { value: "Samsung", label: "Samsung" },
    { value: "Hytera", label: "Hytera" },
    { value: "sepura", label: "Sepura" },
    { value: "Inrico", label: "Inrico" },
    { value: "Makita", label: "Makita" },
    { value: "Callta", label: "Callta" },
    { value: "Honeywell", label: "Honeywell" },
    { value: "Simbol", label: "Simbol" },
    { value: "Ticket", label: "Ticket" },
    { value: "Hanna", label: "Hanna" },
    { value: "Tropicana", label: "Tropicana" },
  ];

  const equipmentDepartments = [
    { value: "Gerência", label: "Gerência" },
    { value: "Coordenação", label: "Coordenação" },
    { value: "Supervisão", label: "Supervisão" },
    { value: "Liderança", label: "Liderança" },
    { value: "LL", label: "LL" },
    { value: "Load Control", label: "Load Control" },
    { value: "PNAE", label: "PNAE" },
    { value: "HCC", label: "HCC" },
    { value: "Ambulift 1", label: "Ambulift 1" },
    { value: "Ambulift 2", label: "Ambulift 2" },
    { value: "Ambulift 3", label: "Ambulift 3" },
    { value: "Administrativo", label: "Administrativo" },
    { value: "Atlas 1", label: "Atlas 1" },
    { value: "Atlas 2", label: "Atlas 2" },
    { value: "ABSA", label: "ABSA" },
    { value: "Auxiliar de Pista", label: "Auxiliar de Pista" },
    { value: "Não especificado", label: "Não Especificado" },
    { value: "Bag Runner", label: "Bag Runner" },
    { vaue: "Carga", label: "Carga" },
    { value: "Rampa Narrow", label: "Rampa Narrow" },
    { value: "Rampa Wide", label: "Rampa Wide" },
    { value: "Limpeza", label: "Limpeza" },
    { value: "DOT", label: "DOT" },
    { value: "Operações Pushback", label: "Operações Pushback" },
  ];

  return (
    <Modal
      title={
        <div className="titleDiv">
          <Title level={4} className="modalTitle">
            {isBatchUpload ? "Cadastro em Massa" : "Cadastro Unitário"}
          </Title>
          <Text type="secondary" className="modalSubTitle">
            {isBatchUpload
              ? "Cadastrar diversos equipamentos de uma só vez"
              : "Cadastrar apenas um equipamento"}
          </Text>
        </div>
      }
      open={open}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel} className="cancelButton">
          Cancelar
        </Button>,
        <Button
          key="submit"
          onClick={handleSubmit}
          className="sendButton"
          loading={loading}
          disabled={loading}
        >
          Cadastrar
        </Button>,
      ]}
      width={600}
    >
      <div className="divButtonTabs">
        <Button
          className={isBatchUpload ? "btnSelected" : "btnNotSelected"}
          onClick={() => setIsBatchUpload(true)}
        >
          Cadastro em Massa
        </Button>
        <Button
          className={!isBatchUpload ? "btnSelected" : "btnNotSelected"}
          onClick={() => setIsBatchUpload(false)}
        >
          Cadastro Unitário
        </Button>
      </div>

      {isBatchUpload ? (
        <div>
          <Dragger
            name="batchFile"
            accept=".xlsx"
            fileList={fileList}
            beforeUpload={(file) => {
              form.setFieldsValue({ batchFile: file });
              setFileList([file]); // Update fileList
              message.success(`${file.name} pronto para envio!`);
              return false;
            }}
            onRemove={() => {
              form.setFieldsValue({ batchFile: null });
              setFileList([]); // Clear fileList when file is removed
              return true;
            }}
            multiple={false}
            className="dragDropContainer"
          >
            <FileAddOutlined className="fileAddIcon" />
            <p className="draggerText">
              Arraste e solte seu arquivo aqui ou clique para selecionar
            </p>
            <Button className="uploadButton">Selecionar Arquivo</Button>
          </Dragger>

          <div className="modelDownloadContainer">
            <PaperClipOutlined />
            <Text>
              <a
                href="/public/templates/modelo_importacao_equipamentos.xlsx"
                download="modelo_importacao_equipamentos.xlsx"
              >
                Baixar o modelo de importação
              </a>
            </Text>
            <DownloadOutlined />
          </div>
        </div>
      ) : (
        <Form form={form} layout="horizontal">
          <Form.Item
            label="Código"
            name="codigo"
            rules={[{ required: true, message: "Este campo é obrigatório" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Tipo"
            name="tipo"
            rules={[{ required: true, message: "Este campo é obrigatório" }]}
            tooltip="Você pode selecionar um tipo ou digitar um novo"
          >
            <AutoComplete
              optionFilterProp="label"
              labelRender={(label) => label}
              options={equipmentTypes}
              onChange={(value) => setSelectedType(value)}
              name={selectedType}
              placeholder="Selecione ou digite um tipo"
              maxCount={1}
              allowClear
              onSelect={(value) => setSelectedType(value)}
            />
          </Form.Item>

          <Form.Item
            label="Descrição"
            name="descricao"
            rules={[{ required: true, message: "Este campo é obrigatório" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Modelo"
            name="modelo"
            rules={[{ required: true, message: "Este campo é obrigatório" }]}
            tooltip="Você pode selecionar um modelo ou digitar um novo"
          >
            <AutoComplete
              optionFilterProp="label"
              labelRender={(label) => label}
              options={equipmentModels}
              onChange={(value) => setSelectedModel(value)}
              name={selectedModel}
              placeholder="Selecione ou digite um tipo"
              maxCount={1}
              allowClear
              onSelect={(value) => setSelectedModel(value)}
            />
          </Form.Item>

          <Form.Item
            label="Fabricante"
            name="fabricante"
            rules={[{ required: true, message: "Este campo é obrigatório" }]}
            tooltip="Você pode selecionar um fabricante ou digitar um novo"
          >
            <AutoComplete
              optionFilterProp="label"
              labelRender={(label) => label}
              options={equipmentManufacturers}
              onChange={(value) => setSelectedManufacturer(value)}
              name={selectedManufacturer}
              placeholder="Selecione ou digite um tipo"
              maxCount={1}
              allowClear
              onSelect={(value) => setSelectedManufacturer(value)}
            />
          </Form.Item>

          <Form.Item
            label="Departamento"
            name="departamento"
            rules={[{ required: true, message: "Este campo é obrigatório" }]}
            tooltip="Você pode selecionar um departamento ou digitar um novo"
          >
            <AutoComplete
              optionFilterProp="label"
              labelRender={(label) => label}
              options={equipmentDepartments}
              onChange={(value) => setSelectedDepartment(value)}
              name={selectedDepartment}
              placeholder="Selecione ou digite um tipo"
              maxCount={1}
              allowClear
              onSelect={(value) => setSelectedDepartment(value)}
            />
          </Form.Item>

          <Form.Item
            label="Número de Série"
            name="numero_serie"
            rules={[{ required: true, message: "Este campo é obrigatório" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Número de Patrimônio"
            name="patrimonio"
            rules={[{ required: true, message: "Este campo é obrigatório" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default AddEquipmentModal;
