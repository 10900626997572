// src/components/QRCodeModal.js
import React from 'react';
import { Modal, Button, Space } from 'antd';
import { QRCode } from 'antd';
import { DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import html2canvas from 'html2canvas';

const LockerQrCodeModal = ({ open, onClose, armario }) => {
  const qrRef = React.useRef();

  const handleDownload = async () => {
    const canvas = await html2canvas(qrRef.current);
    const url = canvas.toDataURL("image/png");
    const link = document.createElement('a');
    link.download = `qrcode-armario-${armario.codigo}.png`;
    link.href = url;
    link.click();
  };

  const handlePrint = async () => {
    try {
      // Capture QR code as image
      const canvas = await html2canvas(qrRef.current);
      const qrImage = canvas.toDataURL('image/png');

      const printWindow = window.open('', '_blank', 'width=800,height=600');
      printWindow.document.write(`
        <html>
          <head>
            <title>QR Code - ${armario.codigo}</title>
            <style>
              body { 
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100vh;
                margin: 0;
                font-family: Arial, sans-serif;
              }
              .container {
                text-align: center;
                padding: 20px;
              }
              .info {
                margin-bottom: 20px;
              }
              .qr-code {
                margin: 20px 0;
              }
              @media print {
                body { height: auto; }
                .container { page-break-inside: avoid; }
              }
            </style>
          </head>
          <body>
            <div class="container">
              <div class="info">
                <p>Código: ${armario.codigo}</p>
              </div>
              <div class="qr-code">
                <img src="${qrImage}" alt="QR Code" style="width: 150px; height: 100px;"/>
              </div>
            </div>
          </body>
        </html>
      `);
      
      printWindow.document.close();

      // Wait for image to load before printing
      printWindow.onload = () => {
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      };
    } catch (error) {
      console.error('Erro ao imprimir:', error);
    }
  };

  return (
    <Modal
      title="QR Code do Armário"
      open={open}
      onCancel={onClose}
      width={350}
      footer={[
        <Space key="actions">
          <Button icon={<DownloadOutlined />} onClick={handleDownload}>
            Download
          </Button>
          <Button icon={<PrinterOutlined />} onClick={handlePrint}>
            Imprimir
          </Button>
        </Space>
      ]}
    >
      <div style={{ textAlign: 'center', marginTop: '20px'}}>
        <div ref={qrRef}>
          <QRCode
            value={JSON.stringify({
              code: armario?.codigo,
              size: armario?.size,
                          })}
            size={300}
            bordered={true}
          />
        </div>
        <p style={{ marginTop: 20, fontWeight: 'bold' }}>{armario?.codigo}</p>
      </div>
    </Modal>
  );
};

export default LockerQrCodeModal;