import React from 'react';
import { Modal, Form, Input, message } from 'antd';

const AssignLockerModal = ({ open, onClose, onSubmit, armario }) => {
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (open && armario?.status === 'LIVRE') {
      form.setFieldsValue({
        locker: armario.codigo,
        colete: '', // Limpa o campo "colete" ao abrir
      });
    } else if (open && armario?.status === 'OCUPADO') {
      form.setFieldsValue({
        locker: armario.codigo,
        colete: armario.being_used_by,
      });
    }
  }, [open, armario, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const status = armario?.status === 'LIVRE' ? 'in_use' : 'available';

      if (armario?.status === 'OCUPADO') {
        Modal.confirm({
          title: `Confirmar liberação do armário ${armario?.codigo}?`,
          content: 'Essa ação irá liberar o armário e desassociar o colete.',
          okText: 'Sim',
          cancelText: 'Não',
          onOk: async () => {
            await onSubmit({ values, status });
            form.resetFields();
            onClose();
          },
        });
      } else {
        await onSubmit({ values, status });
        form.resetFields();
        onClose();
      }
    } catch (error) {
      console.error('Erro ao editar equipamento:', error);
      message.error('Erro ao editar equipamento');
    }
  };

  const handleColeteChange = (e) => {
    const input = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
    const formattedValue = input ? `ORB${input.slice(0, 4)}` : ''; // Adiciona prefixo e limita a 4 números
    form.setFieldsValue({ colete: formattedValue });
  };

  return (
    <Modal
      title={open && armario?.status === 'OCUPADO' ? `Liberar armário ${armario?.codigo}` : `Atribuir armário ${armario?.codigo}`}
      open={open}
      onCancel={onClose}
      onOk={handleSubmit}
      width={600}
      okText={armario?.status === 'OCUPADO' ? 'Liberar Armário' : 'Atribuir Armário'}
      okButtonProps={{ style: { backgroundColor: armario?.status === 'OCUPADO' ? 'green' : 'orange' } }}
      afterOpenChange={(open) => form.focusField('colete') }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit} // Permite envio com ENTER
      >
        <Form.Item
          name="locker"
          label="Armário"
          rules={[{ required: true, message: 'Por favor, insira o código' }]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          name="colete"
          label="Colete"
          autoFocus={true}
          rules={[
            { required: armario?.status === 'LIVRE', message: 'Por favor, insira o número do colete' },
            {
              pattern: /^ORB\d{1,4}$/,
              message: 'O colete deve seguir o formato ORB seguido de até 4 números',
            },
          ]}
        >
          <Input
            onChange={handleColeteChange}
            autoFocus
            placeholder="Digite o número do colete"
            disabled={armario && armario?.status === 'OCUPADO'}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AssignLockerModal;
