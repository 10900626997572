import { Layout, Tooltip, Typography, Form, Input, Button, message, Card, Divider, Switch, Upload, Modal } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import React, { useEffect, useRef, useState } from "react";
import DrawerMenu from "../components/DrawerMenu";
import {
  MenuOutlined,
  CloseOutlined,
  MinusOutlined,
  ImportOutlined,
  ExportOutlined,
  MobileOutlined,
  PlusOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import './EquipmentTransactions.css'
import { newEquipmentTransaction, validateEquipmentCodeService, validateEquipmentEmployeeService } from "../services/equipmentService";
import socket from "../utils/socket";

const { Text } = Typography;

const EquipmentTransactions = () => {
  // Add inside component before state declarations
const equipmentInputRef = useRef(null);
const collaboratorInputRef = useRef(null);

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [equipmentValidated, setEquipmentValidated] = useState(false);
  const [collaboratorValidated, setCollaboratorValidated] = useState(false);
  const [equipmentData, setEquipmentData] = useState(null);
  const [collaboratorData, setCollaboratorData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDamaged, setIsDamaged] = useState(false);
  const [damageModalVisible, setDamageModalVisible] = useState(false);
  const [damageDescription, setDamageDescription] = useState('');
  const [damagePhotos, setDamagePhotos] = useState([]);


  const [form] = Form.useForm();

  const showDrawer = () => setDrawerVisible(true);
  const closeDrawer = () => setDrawerVisible(false);

  // Reset form
const resetForm = () => {
  form.resetFields();
  setEquipmentValidated(false);
  setCollaboratorValidated(false);
  setEquipmentData(null);
  setCollaboratorData(null);
  equipmentInputRef.current?.focus();
};

useEffect(() => {
  if (!equipmentValidated) {
    equipmentInputRef.current?.focus();
  }
}, [equipmentValidated]);

useEffect(() => {
  if (equipmentValidated && !collaboratorValidated) {
    collaboratorInputRef.current?.focus();
  }

  socket.on('equipmentValidated', (data) => {
    console.log('Equipment validated:', data);
  });

  // Cleanup on unmount
  return () => {
    socket.off('equipmentValidated');
  };
}, [equipmentValidated, collaboratorValidated]);

  // Validate equipment code
  const validateEquipmentCode = async () => {
    const code = form.getFieldValue("codigo");
    if (!code) {
      message.error("Por favor, insira o código do equipamento.");
      return;
    }

    setLoading(true);
    try {
      const result = await validateEquipmentCodeService(code);
      if (result.equipment.status === 'damaged') {
        setEquipmentValidated(false);
        message.error("Equipamento danificado, não pode ser emprestado.");
        form.setFieldValue("codigo", "");
      } else if (result.equipment.status === 'deleted') {
        setEquipmentValidated(false);
        message.error("Equipamento não encontrado.");
        form.setFieldValue("codigo", "");
      } else if (result.equipment.status === 'inactive') {
        setEquipmentValidated(false);
        message.error("Equipamento inativo, não pode ser emprestado.");
        form.setFieldValue("codigo", "");
      }
      else {
      setEquipmentData(result.equipment);
      setEquipmentValidated(true);
      message.success("Equipamento validado com sucesso!");
      const type = result.equipment.tipo;
      const manufactures = result.equipment.fabricante;
      const model = result.equipment.modelo;
      const codigo = result.equipment.codigo;
      form.setFieldValue("codigo", `${codigo} - ${type} - ${manufactures} - ${model}`);
      };
    } catch (error) {
      console.error("Erro ao validar equipamento:", error);
      message.error("Código do equipamento inválido.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleGlobalKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault(); // Evita envio padrão de formulários
        if (equipmentValidated && collaboratorValidated) {
          handleSubmit();
        }
      }
  
      if (event.key === "Escape") {
        event.preventDefault();
  
        if (collaboratorValidated) {
          setCollaboratorData(null);
          form.setFieldValue("matricula", "");
          collaboratorInputRef.current?.focus();
          setCollaboratorValidated(false);
        } else if (equipmentValidated) {
          setEquipmentValidated(false);
          setEquipmentData(null);
          form.setFieldValue("codigo", "");
          equipmentInputRef.current?.focus();
        } else {
          resetForm();
        }
      }
    };
  
    document.addEventListener("keydown", handleGlobalKeyDown);
  
    return () => {
      document.removeEventListener("keydown", handleGlobalKeyDown);
    };
  }, [equipmentValidated, collaboratorValidated, collaboratorInputRef, equipmentInputRef]);

  // Validate collaborator
  const validateCollaborator = async () => {
    const matricula = form.getFieldValue("matricula");
    if (!matricula) {
      message.error("Por favor, insira a matrícula.");
      return;
    }

    setLoading(true);
    try {
      const result = await validateEquipmentEmployeeService(matricula);
      setCollaboratorValidated(true);
      setCollaboratorData(result.employee);
      message.success("Colaborador validado com sucesso!");
    } catch (error) {
      console.error("Erro ao validar colaborador:", error);
      message.error("Matrícula inválida.");
    } finally {
      setLoading(false);
    }
  };

  const handleDamageSwitch = (checked) => {
    setIsDamaged(checked);
    if (checked) {
      setDamageModalVisible(true);
    }
  };

  const handleModalOk = () => {
    if (!damageDescription.trim()) {
      message.error('Por favor, descreva os danos');
      return;
    }
    try {
      handleSubmit();} catch (error) {
      console.error('Erro ao realizar transação:', error);
      }
    setDamageDescription('');
    setDamagePhotos([]);
    setIsDamaged(false);
    setDamageModalVisible(false);
  };

  const handleModalCancel = () => {
    setDamageDescription('');
    setDamagePhotos([]);
    setIsDamaged(false);
    setDamageModalVisible(false);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

const handleSubmit = async () => {
  try {
    setLoading(true);

    // Create FormData for multipart/form-data request
    const formData = new FormData();

    // Add transaction data
    formData.append("equipment_id", equipmentData.id);
    formData.append("collaborator_id", collaboratorData.id);
    formData.append("is_damaged", isDamaged);
    if (isDamaged) {
      formData.append("damage_description", damageDescription);
    }

    // Add damage photos if available
    if (isDamaged && damagePhotos.length) {
      damagePhotos.forEach((photo) => {
        if (photo.originFileObj) {
          formData.append("damage_photos", photo.originFileObj); // Sem índices
        }
      });
    }
    // Send FormData to the backend
    await newEquipmentTransaction(formData);

    message.success("Transação realizada com sucesso!");
    resetForm();
  } catch (error) {
    console.error("Erro ao realizar transação:", error);
    message.error("Erro ao realizar transação.");
  } finally {
    setLoading(false);
  }
};

// form submit handler
const onFormFinish = () => {
  if (equipmentValidated && collaboratorValidated) {
    handleSubmit();
  }
};

// keyboard handler
const handleKeyDown = (event) => {

  if (event.key === "Enter") {
    if (equipmentValidated && collaboratorValidated) {
      handleSubmit();
    }
  }

  if (event.key === "Escape") {
    if (collaboratorValidated) {
      setCollaboratorData(null);
      form.setFieldValue("matricula", "");
      collaboratorInputRef.current?.focus();
      setCollaboratorValidated(false);
    } else if (equipmentValidated) {
      setEquipmentValidated(false);
      setEquipmentData(null);
      form.setFieldValue("codigo", "");
      equipmentInputRef.current?.focus();
    } else {
      resetForm();
    }
  }
};

  return (
    <Layout className="equipment-manager-layout" onKeyDown={handleKeyDown} tabIndex={0}>
      <Header className="equipment-header">
        <div className="header-left">
          <MenuOutlined className="menu-icon" onClick={showDrawer} />
          <Text className="header-title">Gerenciador de Equipamentos</Text>
        </div>
        <div className="header-right">
          <Tooltip title="Minimizar">
            <MinusOutlined className="action-icon" />
          </Tooltip>
          <Tooltip title="Sair">
            <CloseOutlined className="action-icon" />
          </Tooltip>
        </div>
      </Header>

      <Content className="equipment-content-form">
      <div className="dashboard-button-container">
    <Button className="buttonDashboard" onClick={() => window.location.href = '/equipamentos'} icon={<BarChartOutlined/>}>
      Dashboard
    </Button>
  </div>
        <div className="form-container">

        <Card 
  title={
    equipmentData?.status === 'available' 
      ? <span><ImportOutlined /> <strong>Entrega</strong> de Equipamento</span>
      : equipmentData?.status === 'in_use' 
        ? <span><ExportOutlined /> <strong>Devolução</strong>  de Equipamento</span>
        : <span><MobileOutlined /> Entrega e Devolução de Equipamentos</span>
  }
>
<Form 
  form={form} 
  layout="vertical"
  onFinish={onFormFinish}
  onKeyDown={onFormFinish}
>
              {/* Equipment Code */}
              <Form.Item
                label="Código do Equipamento"
                name="codigo"
                rules={[{ required: true, message: "Por favor, insira o código do equipamento." }]}
              >
                <Input.Search
                  ref={equipmentInputRef}
                  placeholder="Digite o código do equipamento"
                  enterButton="Continuar"
                  onSearch={validateEquipmentCode}
                  disabled={equipmentValidated}
                  loading={loading}
                  autoFocus
                />
              </Form.Item>

              {/* Collaborator Field */}
              {equipmentValidated && (
                <Form.Item
                  label="Matrícula do Colaborador"
                  name="matricula"
                  rules={[{ required: true, message: "Por favor, insira a matrícula do colaborador." }]}
                >
                  <Input.Search
                    ref={collaboratorInputRef}
                    placeholder="Digite a matrícula do colaborador"
                    enterButton="Continuar"
                    onSearch={validateCollaborator}
                    disabled={collaboratorValidated}
                    loading={loading}
                    autoFocus
                  />
                </Form.Item>
              )}

              {/* Transaction Summary */}
              {equipmentValidated && collaboratorValidated && (
                <div>
                  <Divider />
                  <Typography.Title level={5}>Resumo da Transação</Typography.Title>
                  <p>
                    <strong>Equipamento:</strong> {equipmentData.tipo} - {equipmentData.fabricante} - {equipmentData.modelo}
                  </p>
                  <p>
                    <strong>Colaborador:</strong> {collaboratorData.matricula} - {collaboratorData.nome_completo} - {collaboratorData.funcao}
                  </p>
                  {equipmentData?.status === 'in_use' && (
  <Form.Item >
    <Switch
      checked={isDamaged}
      onChange={handleDamageSwitch}
      unCheckedChildren="Danificado"
      loading={damageModalVisible}
      checkedChildren="Aguardando"
      style={isDamaged? { backgroundColor: '#ff4d4f', borderColor: '#ff4d4f' } : {}}
    />
  </Form.Item>
)}
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button onClick={() => resetForm()}>Cancelar</Button>
                    <Button type={equipmentValidated || collaboratorValidated ? "primary" : "danger"} onClick={handleSubmit} loading={loading} disabled={!equipmentValidated || !collaboratorValidated}>
                      Confirmar Transação
                    </Button>
                  </div>
                </div>
              )}
            </Form>
          </Card>
        </div>
      </Content>

      <DrawerMenu open={drawerVisible} onClose={closeDrawer} title="Menu">
        <Text>Conteúdo do Drawer</Text>
      </DrawerMenu>
      <Modal
  title="Registro de Danos"
  open={damageModalVisible}
  onOk={handleModalOk}
  onCancel={handleModalCancel}
  width={600}
>
  <Form layout="vertical">
    <Form.Item 
      label="Descrição dos Danos"
      required
    >
      <Input.TextArea
        rows={4}
        value={damageDescription}
        onChange={(e) => setDamageDescription(e.target.value)}
        placeholder="Descreva os danos identificados no equipamento"
      />
    </Form.Item>
    
    <Form.Item label="Fotos dos Danos">
      <Upload
        listType="picture-card"
        fileList={damagePhotos}
        onChange={({ fileList }) => setDamagePhotos(fileList)}
        beforeUpload={(file) => {
          const isImage = file.type.startsWith('image/');
          if (!isImage) {
            message.error('Você só pode fazer upload de imagens!');
          }
          const isLt2M = file.size / 1024 / 1024 < 2;
          if (!isLt2M) {
            message.error('A imagem deve ser menor que 2MB!');
          }
          return isImage && isLt2M;
        }}
        maxCount={5}
      >
        {damagePhotos.length >= 5 ? null : uploadButton}
      </Upload>
    </Form.Item>
  </Form>
</Modal>
    </Layout>
  );
};

export default EquipmentTransactions;
