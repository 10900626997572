// src/components/InfoEquipmentModal.js
import React, { useState } from "react";
import {
  Modal,
  Descriptions,
  DatePicker,
  Button,
  Table,
  Space,
  Tooltip,
  Tag,
  Image,
  notification,
} from "antd";
import Ribbon from "antd/es/badge/Ribbon";
import { CameraOutlined, FileExcelOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import locale from "antd/es/date-picker/locale/pt_BR";
import { fetchEquipmentHistory } from "../services/equipmentService";

const { RangePicker } = DatePicker;

const InfoEquipmentModal = ({ open, onClose, equipment }) => {
  const [dateRange, setDateRange] = useState(null);
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageLoadErrors, setImageLoadErrors] = useState({});

  const getStatusColor = (status) => {
    const colors = {
      Disponivel: "green",
      "Em uso": "orange",
      Danificado: "red",
      Inativo: "yellow",
      damaged: "red",
      in_use_24h: "red",
      in_use_12h: "red",
    };
    return colors[status] || "default";
  };

  const getStatusText = (status) => {
    const texts = {
      available: "Disponível",
      in_use: "Em Uso",
      damaged: "Danificado",
      inactive: "Inativo",
      in_use_24h: "+24h",
      in_use_12h: "+12h",
    };
    return texts[status] || status;
  };

  const historyColumns = [
    {
      title: "Data/Hora",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (text) => new Date(text).toLocaleString("pt-BR"),
    },
    {
      title: "Ação",
      dataIndex: "action",
      key: "action",
      render: (text) => (
        <Tag
          color={
            text === "Devolução" || text === "Reativado" || text === "Reparado"
              ? "green"
              : text === "Danificado"
              ? "red"
              : text === "Inativado"
              ? "yellow"
              : "blue"
          }
        >
          {text}
        </Tag>
      ),
    },
    {
      title: "Colaborador",
      dataIndex: "user",
      key: "user",
      render: (text, record) => (
        <Tooltip title={`Matrícula: ${record.badge}`}>
          {`${record.badge} - ` + text}
        </Tooltip>
      ),
    },
    {
      title: "Responsável CEOP",
      dataIndex: "processedByName",
      key: "processedByBadge",
      render: (text, record) => (
        <Tooltip
          title={`${record.processedByName || ""} (${
            record.processedByBadge || ""
          })`}
        >
          <span>{`${record.processedByBadge || ""} - ${
            record.processedByName || ""
          } ${record.processedByLastname}`}</span>
        </Tooltip>
      ),
    },
    {
      title: "Danificado",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        // Skip rendering if status is damaged but no damage report exists
        if (text !== "damaged" && !record.observations) {
          return "Sem registros";
        }

        // For all other statuses or valid damage reports
        if (text && record.observations) {
          text = "damaged";
          return <Tag color={"red"}>{getStatusText(text)}</Tag>;
        }

        return "-";
      },
    },
    {
      title: "Histórico de Danos",
      key: "damage",
      render: (_, record) => (
        <Space direction="vertical" size="small">
          {record.observations && <div>{record.observations}</div>}
          {record.photos && record.photos.length > 0 && (
            <Button
              type="link"
              icon={<CameraOutlined />}
              onClick={() => {
                setSelectedImages(record.photos); // Define as fotos selecionadas
                setImageModalVisible(true); // Abre o modal
              }}
            >
              Ver fotos ({record.photos.length})
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const handleDateRangeChange = async (dates) => {
    setDateRange(dates);
    if (dates) {
      setLoading(true);
      try {
        // Format start date: YYYY-MM-DD 00:00:00
        const startDate = dates[0].format("YYYY-MM-DD");

        // Format end date: YYYY-MM-DD 23:59:59
        const endDate = dates[1].format("YYYY-MM-DD");

        const response = await fetchEquipmentHistory(
          equipment.equipment_id,
          startDate,
          endDate
        );

        setHistoryData(response.history);
      } catch (error) {
        console.error("Erro ao buscar histórico:", error);
        if (error.status === 404) {
          notification.error({
            message: "Ops!",
            description:
              "Parece que este equipamento não possui histórico de movimentações",
          });
          setDateRange(null);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const exportToExcel = () => {
    if (historyData.length === 0) return;

    // Find max number of photos across all records
    const maxPhotos = Math.max(
      ...historyData.map((row) =>
        Array.isArray(row.photos) ? row.photos.length : 0
      )
    );

    const formattedHistory = historyData.map((row) => {
      // Create base object with standard fields
      const baseObj = {
        "Data e Hora": new Date(row.timestamp).toLocaleString(),
        Ação: row.action,
        Colaborador: `${row.badge} - ${row.user}`,
        "Matrícula do Colaborador": row.user_badge,
        "Responsável pela entrega/devolução": `${row.processedByBadge} - ${row.processedByName} ${row.processedByLastname}`,
        Observações: row.observations || "",
      };

      // Add photo columns dynamically
      const photos = Array.isArray(row.photos) ? row.photos : [];
      for (let i = 0; i < maxPhotos; i++) {
        baseObj[`Foto ${i + 1}`] = photos[i] ? `${photos[i]}` : "";
      }

      return baseObj;
    });

    const ws = XLSX.utils.json_to_sheet(formattedHistory);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Histórico");

    const fileName = `historico_${equipment.equipment_code}_${
      new Date().toISOString().split("T")[0]
    }.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  const handleClose = () => {
    // Reset all relevant states
    setHistoryData([]);
    setDateRange(null);
    setLoading(false);
    // Call original onClose prop
    onClose();
  };

  if (!equipment) return null;

  return (
    <>
      <Modal
        title="Detalhes do Equipamento"
        open={open}
        onCancel={handleClose}
        footer={null}
        width={1000}
      >
        <Ribbon
          text={getStatusText(equipment.equipment_status)}
          color={getStatusColor(equipment.equipment_status)}
          placement="end"
          style={{
            marginTop:
              equipment.usage_duration_category === "in_use_recent" ? 10 : -5,
          }}
        >
          <Ribbon
            text={getStatusText(equipment.usage_duration_category)}
            color={getStatusColor(equipment.usage_duration_category)}
            placement="end"
            style={{
              marginTop:
                equipment.usage_duration_category === "in_use_recent" ? 0 : 22,
              display:
                equipment.usage_duration_category === "in_use_recent"
                  ? "none"
                  : "block",
            }}
          >
            <Descriptions bordered column={2}>
              <Descriptions.Item
                label="Equipamento"
                span={2}
              >{`${equipment.equipment_type} - ${equipment.equipment_code}`}</Descriptions.Item>
              <Descriptions.Item label="Código">
                {equipment.equipment_code}
              </Descriptions.Item>
              <Descriptions.Item label="Tipo">
                {equipment.equipment_type}
              </Descriptions.Item>
              <Descriptions.Item label="Setor">
                {equipment.equipment_sector}
              </Descriptions.Item>
              <Descriptions.Item label="Nº Série">
                {equipment.equipment_serial_number}
              </Descriptions.Item>
              <Descriptions.Item label="Nº Patrimônio">
                {equipment.equipment_patrimony}
              </Descriptions.Item>
              <Descriptions.Item label="Último Usuário">
                {equipment.last_used_by}
              </Descriptions.Item>
              <Descriptions.Item label="Última Utilização">
                {equipment.last_used_at}
              </Descriptions.Item>
            </Descriptions>
          </Ribbon>
        </Ribbon>

        <div style={{ marginTop: 24 }}>
          <Space style={{ marginBottom: 16 }}>
            <RangePicker
              locale={locale}
              onChange={handleDateRangeChange}
              style={{ width: 400 }}
              format={"DD/MM/YYYY"}
            />
            <Button
              type="primary"
              icon={<FileExcelOutlined />}
              onClick={exportToExcel}
              disabled={!historyData.length}
            >
              Exportar Excel
            </Button>
          </Space>

          <Table
            columns={historyColumns}
            dataSource={historyData}
            loading={loading}
            pagination={{ pageSize: 5 }}
            rowKey="id"
            size="small"
          />
        </div>
      </Modal>
      <Modal
        title="Fotos do Dano"
        open={imageModalVisible}
        onCancel={() => {
          setImageModalVisible(false);
          setImageLoadErrors({});
        }}
        footer={null}
        width={800}
      >
        <Image.PreviewGroup>
          <Space wrap>
            {selectedImages.filter(Boolean).map((photo, index) => {
              // Gera a URL completa se necessário
              const imageUrl = `${photo}`;

              return (
                <div key={index} style={{ position: "relative" }}>
                  <Image
                    width={200}
                    src={imageUrl} // URL completa da imagem
                    alt={`Dano ${index + 1}`}
                    fallback="https://via.placeholder.com/200?text=Imagem+não+encontrada"
                    onError={() => {
                      console.error("Erro ao carregar imagem:", imageUrl);
                      setImageLoadErrors((prev) => ({
                        ...prev,
                        [index]: true,
                      }));
                    }}
                    preview={!imageLoadErrors[index]}
                  />
                  {imageLoadErrors[index] && (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "red",
                      }}
                    >
                      Erro ao carregar imagem
                    </div>
                  )}
                </div>
              );
            })}
          </Space>
        </Image.PreviewGroup>
      </Modal>
    </>
  );
};

export default InfoEquipmentModal;
