import api from "./api";

export async function getAccessLevel() {
  try {
    const response = await api.get(`/users/level`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Inclua o token de autenticação, se necessário
      },
    });

    // Retorna o nível de acesso como objeto ou array
    const nivelAcesso = response.data.nivel_acesso;

    return nivelAcesso;
  } catch (error) {
    console.error("Erro ao buscar o nível de acesso:", error);
    throw error; // Rejeita a promise com o erro para ser tratado no componente
  }
}

export async function userLogout() {
  try {
    localStorage.removeItem("token");
    window.location.href = "/login";
  } catch (error) {
    console.error("Erro ao fazer logout:", error);
    throw error;
  }
}

export async function getCollaboratorData(matricula) {
  try {
    const response = await api.get(`/users/getcollaboratorinfo/${matricula}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Inclua o token de autenticação, se necessário
      },
    });

    // Retorna os dados do colaborador
    return response;
  } catch (error) {
    console.error("Erro ao buscar os dados do colaborador:", error);
    throw error; // Rejeita a promise com o erro para ser tratado no componente
  }
}

export async function addUser(formData) {
  const token = localStorage.getItem("token");
  try {
    const response = await api.post("/users/adduser", formData, {
      headers: {
        Authorization: `Bearer ${token}`, // Inclua o token de autenticação, se necessário
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erro ao adicionar usuário:", error);
    throw error;
  }
}

export const insertBatchUsers = async (file) => {
  const token = localStorage.getItem("token");

  // Criar FormData para envio do arquivo
  const formData = new FormData();
  formData.append("file", file);

  const response = await api.post("/addusers/batch", formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data", // Necessário para envio de arquivos
    },
  });

  return response.data;
};

export async function getAuthorizedModules() {
  try {
    const response = await api.get(`/users/modules`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Inclua o token de autenticação, se necessário
      },
    });

    // Retorna os módulos autorizados
    return response.data.modules;
  } catch (error) {
    console.error("Erro ao buscar os módulos autorizados:", error);
    throw error; // Rejeita a promise com o erro para ser tratado no componente
  }
}

export async function getRandomForgotPwdQuestion(matricula) {
  try {
    const response = await api.get(`/users/forgotpwd/${matricula}`);

    return response.data;
  } catch (error) {
    console.error("Erro ao gerar nova senha:", error);
    throw error;
  }
}
