import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAccessLevel } from "../services/userServices";

const PrivateRoute = ({ children, allowedRoles }) => {
  const [isAuthorized, setIsAuthorized] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setIsAuthorized(false);
        return;
      }

      try {
        const accessLevel = await getAccessLevel();
        const hasAccess = !allowedRoles || allowedRoles.includes(accessLevel);
        setIsAuthorized(hasAccess);
      } catch (error) {
        setIsAuthorized(false);
        localStorage.removeItem("token");
      }
    };

    checkAuth();
  }, [allowedRoles]);

  if (isAuthorized === null) {
    return null; // ou um componente de loading
  }

  if (!isAuthorized) {
    // Redireciona para login se não houver token
    if (!localStorage.getItem("token")) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
    // Redireciona para a página anterior se não tiver acesso
    navigate(-1, { replace: true });
    return null;
  }

  return children;
};

export default PrivateRoute;
