import React, { useEffect, useState } from "react";
import { Drawer, Menu, Divider } from "antd";
import { UserOutlined, AppstoreOutlined, LogoutOutlined, ProfileOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import logoTop from '../assets/logos/winglet_logo.png';
import logoFooter from '../assets/logos/powered_by.png';
import { getAccessLevel, getAuthorizedModules } from "../services/userServices";
import AddUserModal from "./AddUserModal";

const DrawerMenu = ({ open, onClose }) => {
    const [accessLevel, setAccessLevel] = useState(null);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [modules, setModules] = useState([]);

    useEffect(() => {
        const fetchAccessLevel = async () => {
            try {
                const level = await getAccessLevel();
                setAccessLevel(level);
            } catch (error) {
                console.error('Error fetching access level:', error);
            }
        };
        const fetchUserModules  = async () => {
            try {
                const modules = await getAuthorizedModules();
                setModules(modules);
            } catch (error) {
                console.error('Erro ao buscar módulos do usuário:', error);
            }
        };

        fetchAccessLevel();
        fetchUserModules();
    }, []);

    const showAddUser = () => setShowAddUserModal(true);

    const handleModuleClick = (path) => {
        window.location.href = `${path}`;
        onClose();
    }


    return (
        <Drawer
            title={
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src={logoTop} alt="Logo" style={{ height: 30 }} />
                </div>
            }
            placement="left"
            onClose={onClose}
            open={open}
            closeIcon={null}
            width={256}
            styles={{body:{ padding: 0, display: "flex", flexDirection: "column", height: "100%" }}}
        >
            <Menu mode="inline" style={{ flex: 1 }}>
                <Menu.SubMenu key="manage-users" icon={<UserOutlined />} title="Gerenciar Usuários" disabled={accessLevel === 'user'}>
                    <Menu.Item key="add-user" onClick={showAddUser}>Adicionar Usuários</Menu.Item>
                    <Menu.Item key="list-users">Lista de Usuários</Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu key="my-systems" icon={<AppstoreOutlined />} title="Meus Sistemas">
                {modules.map(module => (
                        <Menu.Item key={module.id || module.key} onClick={() => handleModuleClick(module.path)}>
                            {module.name.toUpperCase() || 'NENHUM MODULO DISPONÍVEL'}
                        </Menu.Item>
                    ))}
                </Menu.SubMenu>
                <Divider />
                <Menu.Item key="profile" icon={<ProfileOutlined />}>
                    Meu perfil
                </Menu.Item>
                <Menu.Item key="support" icon={<QuestionCircleOutlined />}>
                    Suporte
                </Menu.Item>
                <Divider />
                <Menu.Item key="logout" icon={<LogoutOutlined />}>
                    Sair
                </Menu.Item>
            </Menu>
            <AddUserModal open={showAddUserModal} onClose={() => setShowAddUserModal(false)} onCancel={() => setShowAddUserModal(false)} level={accessLevel}/>
            <div style={{ padding: 16, textAlign: "center" }}>
                <img src={logoFooter} alt="Footer Logo" style={{ height: 30 }} />
            </div>
        </Drawer>
    );
};

export default DrawerMenu;
