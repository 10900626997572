// src/components/EditEquipmentModal.js
import React from 'react';
import { Modal, Form, Input, Select, message } from 'antd';

const EditEquipmentModal = ({ open, onClose, onSubmit, equipment }) => {
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (open && equipment) {
      form.setFieldsValue({
        codigo: equipment.equipment_code,
        tipo: equipment.equipment_type,
        descricao: equipment.equipment_description,
        modelo: equipment.equipment_model,
        fabricante: equipment.equipment_manufacturer,
        numero_serie: equipment.equipment_serial_number,
        patrimonio: equipment.equipment_patrimony,
        departamento: equipment.department,
      });
    }
  }, [open, equipment, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      await onSubmit(equipment.equipment_id, values);
      form.resetFields();
      onClose();
    } catch (error) {
      console.error('Erro ao editar equipamento:', error);
      message.error('Erro ao editar equipamento');
    }
  };

  return (
    <Modal
      title="Editar Equipamento"
      open={open}
      onCancel={onClose}
      onOk={handleSubmit}
      width={600}
    >
      <Form
        form={form}
        layout="vertical"
      >
        <Form.Item
          name="codigo"
          label="Código"
          rules={[{ required: true, message: 'Por favor, insira o código' }]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          name="tipo"
          label="Tipo"
          rules={[{ required: true, message: 'Por favor, insira o tipo' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="modelo"
          label="Modelo"
          rules={[{ required: true, message: 'Por favor, insira o modelo' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="descricao"
          label="Descrição"
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          name="fabricante"
          label="Fabricante"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="numero_serie"
          label="Número de Série"
          rules={[{ required: true, message: 'Por favor, insira o número de série' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="patrimonio"
          label="Patrimônio"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="departamento"
          label="Departamento"
          rules={[{ required: true, message: 'Por favor, selecione o departamento' }]}
        >
          <Select>
            <Select.Option value="TI">TI</Select.Option>
            <Select.Option value="RH">RH</Select.Option>
            <Select.Option value="Financeiro">Financeiro</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditEquipmentModal;